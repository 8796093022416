import { Icon, IconProps } from '@chakra-ui/react'

export function CloseFullScreenIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 18 18" fill="none" {...props}>
      <path
        d="M17.3334 1.84166L12.9251 6.24999L15.6667 8.99999H9.00008V2.33332L11.7417 5.07499L16.1584 0.666656L17.3334 1.84166ZM1.84175 17.3333L6.25008 12.925L9.00008 15.6667V8.99999H2.33341L5.07508 11.7417L0.666748 16.1583L1.84175 17.3333Z"
        fill="currentColor"
      />
    </Icon>
  )
}

import { Icon, IconProps } from '@chakra-ui/react'

export function OffersIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 20 20" fill="none" {...props}>
      <g clipPath="url(#clip0_425_997)">
        <path
          d="M16.6666 4.99999H14.85C14.9416 4.74166 15 4.45832 15 4.16666C15 2.78332 13.8833 1.66666 12.5 1.66666C11.625 1.66666 10.8666 2.11666 10.4166 2.79166L9.99996 3.34999L9.58329 2.78332C9.13329 2.11666 8.37496 1.66666 7.49996 1.66666C6.11663 1.66666 4.99996 2.78332 4.99996 4.16666C4.99996 4.45832 5.05829 4.74166 5.14996 4.99999H3.33329C2.40829 4.99999 1.67496 5.74166 1.67496 6.66666L1.66663 15.8333C1.66663 16.7583 2.40829 17.5 3.33329 17.5H16.6666C17.5916 17.5 18.3333 16.7583 18.3333 15.8333V6.66666C18.3333 5.74166 17.5916 4.99999 16.6666 4.99999ZM12.5 3.33332C12.9583 3.33332 13.3333 3.70832 13.3333 4.16666C13.3333 4.62499 12.9583 4.99999 12.5 4.99999C12.0416 4.99999 11.6666 4.62499 11.6666 4.16666C11.6666 3.70832 12.0416 3.33332 12.5 3.33332ZM7.49996 3.33332C7.95829 3.33332 8.33329 3.70832 8.33329 4.16666C8.33329 4.62499 7.95829 4.99999 7.49996 4.99999C7.04163 4.99999 6.66663 4.62499 6.66663 4.16666C6.66663 3.70832 7.04163 3.33332 7.49996 3.33332ZM16.6666 15.8333H3.33329V14.1667H16.6666V15.8333ZM16.6666 11.6667H3.33329V7.49999C3.33329 7.04166 3.70829 6.66666 4.16663 6.66666H7.56663L6.33329 8.34999C6.05829 8.72499 6.14163 9.24999 6.51663 9.51666C6.88329 9.78332 7.40829 9.69999 7.67496 9.33332L9.99996 6.16666L12.325 9.33332C12.5916 9.69999 13.1166 9.78332 13.4833 9.51666C13.8583 9.24999 13.9416 8.72499 13.6666 8.34999L12.4333 6.66666H15.8333C16.2916 6.66666 16.6666 7.04166 16.6666 7.49999V11.6667Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_425_997">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </Icon>
  )
}

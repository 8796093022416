import type { Styles } from '@chakra-ui/theme-tools'
import { mode } from '@chakra-ui/theme-tools'
import { extendTheme } from '@chakra-ui/react'
import components from './components'
import colors from './colors'

const breakpoints = {
  xs: '30em', // 480px
  sm: '36em', // 576px
  md: '48em', // 768px
  lg: '62em', // 992px
  xl: '75em', // 1200px
  xxl: '90.625em', // 1450px
  xxxl: '108.75em', // 1740px
}

const space = {
  px: '1px',
  0.5: '2px',
  1: '4px',
  1.5: '6px',
  2: '8px',
  2.5: '10px',
  3: '12px',
  3.5: '14px',
  4: '16px',
  5: '20px',
  6: '24px',
  7: '24px',
  8: '32px',
  9: '36px',
  10: '40px',
  12: '48px',
  14: '56px',
  16: '64px',
  20: '80px',
  24: '96px',
  28: '112px',
  32: '128px',
  36: '144px',
  40: '160px',
  44: '176px',
  48: '192px',
  52: '208px',
  56: '224px',
  60: '240px',
  64: '256px',
  72: '288px',
  80: '320px',
  96: '384px',
}

const sizes = {
  ...space,
  '3xs': '224px',
  '2xs': '256px',
  xs: '320px',
  sm: '384px',
  md: '448px',
  lg: '512px',
  xl: '576px',
  '2xl': '672px',
  '3xl': '768px',
  '4xl': '896px',
  '5xl': '1024px',
  '6xl': '1152px',
  '7xl': '1280px',
  '8xl': '1440px',
  container: {
    sm: '540px',
    md: '720px',
    lg: '960px',
    xl: '1140px',
    xxl: '1320px',
  },
}

const shadows = {
  outline: '0 0 2px 2px rgba(255, 154, 68, 0.4)', // box shadow for cards
  containerSection: '0px -110px 80px rgba(0, 0, 0, 0.04)', // box shadow for big section with content
  card: '0px 2px 4px rgba(0, 0, 0, 0.06)',
  form: '0px 3px 19px 2px rgba(0, 0, 0, 0.25)',
}

const styles: Styles = {
  global: (props) => ({
    html: {
      scrollBehavior: 'smooth',
    },
    body: {
      fontSize: '16px',
      bg: mode('white', 'darkModeBG')(props),
      color: mode('black', 'white')(props),
    },

    '.show-pc': {
      display: { base: 'none', md: 'block' },
    },
    '.show-mobile': {
      display: { base: 'block', md: 'none' },
    },
    '.rs-picker-menu': {
      backgroundColor: mode('white', 'black.100')(props),
    },
    ':root': {
      '--chakra-zIndices-modal': 10010,
    },
  }),
}

const fonts = {
  heading: `'Roobert Medium', sans-serif`,
  body: `'Roobert Medium', sans-serif`,
}

const theme = extendTheme({
  breakpoints,
  components,
  colors,
  sizes,
  shadows,
  styles,
  fonts,
  fontSizes: {
    xs: '12px',
    sm: '14px',
    md: '16px',
    lg: '18px',
    xl: '20px',
    '2xl': '24px',
    '3xl': '30px',
    '4xl': '36px',
    '5xl': '48px',
    '6xl': '60px',
    '7xl': '72px',
    '8xl': '96px',
    '9xl': '128px',
  },
  space,
})

export default theme

import type { GlobalStyleProps } from '@chakra-ui/theme-tools'
import { mode } from '@chakra-ui/theme-tools'

const Text = {
  variants: {
    withDarkMode: (props: GlobalStyleProps) => ({
      color: mode('black', '#E5E5E5')(props),
    }),
    gray: (props: GlobalStyleProps) => ({
      color: mode('gray.850', 'gray.220')(props),
      opacity: mode(1, 0.7)(props),
    }),
    orangeGradient: (props: GlobalStyleProps) => ({
      background: 'gradient.orange',
      '&': {
        backgroundClip: 'text',
        textFillColor: 'transparent',
      },
    }),
  },
}

export default Text

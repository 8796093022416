import { ReactNode } from 'react'
import {
  Box,
  Center,
  BoxProps,
  useColorModeValue,
  useDisclosure,
  useStyleConfig,
} from '@chakra-ui/react'
import NextLink from 'next/link'
import { Link } from '@chakra-ui/react'
import { motion } from 'framer-motion'
import { CompassLogo } from '@/components/CompassLogo'
import { Menu } from './Menu'
import { Header } from './Header'

type Props = {
  isPageBlurred: Boolean
  children: ReactNode
}

const MotionBox = motion(Box)
export const Mobile = ({ isPageBlurred = false, children }: Props) => {
  const styles = useStyleConfig('Box', { variant: 'white' })
  const { getButtonProps, getDisclosureProps, isOpen, onClose } =
    useDisclosure()

  return (
    <>
      <Box
        __css={styles}
        height="60px"
        boxShadow="0px 4px 4px rgba(0, 0, 0, 0.03)"
        position="relative"
      >
        <Box {...getButtonProps()}>
          <CloseButton isOpen={isOpen} />
        </Box>
        <Center height="100%">
          <Box width="140px">
            <NextLink href={'/'} passHref>
              <Link>
                <CompassLogo variant="blacklogo" />
              </Link>
            </NextLink>
          </Box>
        </Center>
      </Box>
      <MotionBox
        {...getDisclosureProps()}
        __css={styles}
        hidden={false}
        initial={{ x: '-100%' }}
        animate={{ x: isOpen ? '0' : '-100%' }}
        transition={{
          type: 'spring',
          stiffness: 100,
          damping: 18,
        }}
        pos="absolute"
        maxW="100vw"
        minW="100vw"
        height="100%"
        zIndex="1000"
        padding="36px"
        overflowX="hidden"
        overflowY="auto"
      >
        <Menu onClose={onClose} />
      </MotionBox>
      <Box padding="16px">
        <Header />
        <Box pt="22px" filter={isPageBlurred ? 'blur(3px)' : 'none'}>
          {children}
        </Box>
      </Box>
    </>
  )
}

const Bar = ({ ...rest }: BoxProps) => (
  <Box
    position="absolute"
    height="3px"
    width="18px"
    bg={useColorModeValue('black', 'gray.250')}
    borderRadius="md"
    transform="rotate(0)"
    transition="all .2s ease-in"
    {...rest}
  />
)
const CloseButton = ({ isOpen }: { isOpen: boolean }) => (
  <Center position="absolute" height="60px" left="16px">
    <Box
      className={`close-container ${isOpen && 'open'}`}
      position="relative"
      width="18px"
      height="13px"
      cursor="pointer"
      css={{
        '&.open .bar1': {
          transform: 'rotate(-45deg)',
          marginTop: '25%',
        },
        '&.open .bar2': {
          transform: 'rotate(45deg)',
          bottom: '45%',
        },
      }}
    >
      <Bar className="bar1" />
      <Bar className="bar2" bottom="0" />
    </Box>
  </Center>
)

import { gql } from '@apollo/client'

export const getCompassAgreements = gql`
  query getCompassAgreements {
    allCompassAgreements {
      nodes {
        id
        type
        uri
        version
        document
        created
      }
    }
  }
`

export const getRequiredAgreements = gql`
  query getRequiredAgreements {
    getRequiredAgreements {
      nodes {
        agreementId
        uri
        type
      }
    }
  }
`

export const signAgreementOnCheckout = gql`
  mutation signAgreementOnCheckout {
    checkoutAgreementSigning(input: {}) {
      clientMutationId
      boolean
    }
  }
`

export const signAgreementOnPopup = gql`
  mutation signAgreementOnPopup($agreementId: UUID!) {
    popupAgreementSigning(input: { agreementId: $agreementId }) {
      clientMutationId
      boolean
    }
  }
`

export const signAgreementById = gql`
  mutation signAgreementById($agreementId: UUID!) {
    signAgreementById(input: { agreementId: $agreementId}) {
      clientMutationId
      boolean
    }
  }
`

export const dismissAlert = (alertName: string) => {
  if (typeof window !== 'undefined') {
    localStorage.setItem(alertName, 'false')
  }
}

export const showAlertNext = (alertName: string) => {
  if (typeof window !== 'undefined') {
    localStorage.setItem(alertName, 'true')
  }
}

export const getAlertState = (alertName: string) => {
  if (typeof window !== 'undefined') {
    return localStorage.getItem(alertName)
  }
  return null
}

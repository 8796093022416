import NextLink from 'next/link'
import { useRouter } from 'next/router'
import { Button, ButtonProps } from '@chakra-ui/react'
import { useTranslations } from 'next-intl'

import { useAuthContext } from '@/hooks/useAuthContext'
import { useLogout } from '@/hooks/useAuthActions'

const defaultButtonProps = {
  fontSize: 'sm',
  fontWeight: 500,
  colorScheme: 'gray',
  variant: 'solid',
}

export function AuthButton({ ...rest }: ButtonProps) {
  const t = useTranslations()
  const router = useRouter()
  const logout = useLogout(router)

  const { isAuthenticated, isFetched } = useAuthContext()

  if (isAuthenticated && isFetched) {
    return (
      <Button as="button" {...defaultButtonProps} onClick={logout} {...rest}>
        {t('buttons.sign-out')}
      </Button>
    )
  }

  return (
    <NextLink href={'/sign-in'} passHref>
      <Button as={'a'} {...defaultButtonProps} {...rest}>
        {t('buttons.sign-in')}
      </Button>
    </NextLink>
  )
}

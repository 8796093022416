import { Icon, IconProps } from '@chakra-ui/react'

export function SettingsIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 20 20" fill="none" {...props}>
      <line
        x1="3.5"
        y1="5.5"
        x2="16.5"
        y2="5.5"
        stroke="currentColor"
        strokeLinecap="round"
      />
      <line
        x1="3.5"
        y1="13.5"
        x2="16.5"
        y2="13.5"
        stroke="currentColor"
        strokeLinecap="round"
      />
      <line
        x1="3.5"
        y1="9.5"
        x2="16.5"
        y2="9.5"
        stroke="currentColor"
        strokeLinecap="round"
      />
      <circle cx="13.5" cy="5.5" r={1} fill="white" stroke="currentColor" />
      <circle cx="13.5" cy="13.5" r={1} fill="white" stroke="currentColor" />
      <circle cx="6.5" cy="9.5" r={1} fill="white" stroke="currentColor" />
    </Icon>
  )
}

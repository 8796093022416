const colors = {
  brand: {
    50: '#fff8eb', // compass orange light color,
    100: '#FFD8CB',
    200: '#FFD840',
    300: '#FFB400',
    500: '#ff9a44', // compass orange lighten color
    550: '#f09742', // light orange for text hover
    600: '#f63', // compass orange color
    650: '#FF531C',
  },
  black: {
    100: '#303337',
    200: '#27292C',
    300: '#141415',
    400: '#1D1E1F',
    500: '#12131A',
  },
  dollarGreen: '#6FCF97',
  btcOrange: '#F7931A',
  brandBlue: {
    50: '#3F89F6', // compass brand blue color
  },
  brandGreen: {
    50: '#27AE60',
  },
  brandYellow: {
    50: '#FEDA5C', // compass brand yellow color
  },
  darkModeBG: '#1B202B',
  darkModeSection: '#4C5566',
  // this colorScheme is used for white buttons on black background in dark mode only
  alwaysWhite: {
    200: '#fff',
    300: '#e2e8f0',
    400: '#e1e1e1',
    600: '#b3aeae',
  },
  gradient: {
    orange: 'linear-gradient(295.37deg, #FF531C 13.13%, #FF9A44 85.35%)',
    'orange-to-right': 'linear-gradient(45deg, #FF531C, #FF9A44)',
  },

  transparent: 'transparent',
  current: 'currentColor',
  white: '#FFFFFF',

  whiteAlpha: {
    50: 'rgba(255, 255, 255, 0.04)',
    100: 'rgba(255, 255, 255, 0.06)',
    200: 'rgba(255, 255, 255, 0.08)',
    300: 'rgba(255, 255, 255, 0.16)',
    400: 'rgba(255, 255, 255, 0.24)',
    500: 'rgba(255, 255, 255, 0.36)',
    600: 'rgba(255, 255, 255, 0.48)',
    700: 'rgba(255, 255, 255, 0.64)',
    800: 'rgba(255, 255, 255, 0.80)',
    900: 'rgba(255, 255, 255, 0.92)',
  },

  blackAlpha: {
    50: 'rgba(0, 0, 0, 0.04)',
    100: 'rgba(0, 0, 0, 0.06)',
    200: 'rgba(0, 0, 0, 0.08)',
    300: 'rgba(0, 0, 0, 0.16)',
    400: 'rgba(0, 0, 0, 0.24)',
    500: 'rgba(0, 0, 0, 0.36)',
    600: 'rgba(0, 0, 0, 0.48)',
    700: 'rgba(0, 0, 0, 0.64)',
    800: 'rgba(0, 0, 0, 0.80)',
    900: 'rgba(0, 0, 0, 0.92)',
  },

  grayAlpha: {
    50: 'rgba(237, 240, 247, 0.4)',
  },

  greenAlpha: {
    300: 'rgba(39, 174, 96, 0.1)',
  },

  gray: {
    40: '#FAFAFA',
    45: '#f5f5f5',
    50: '#f6f6f6', // compass gray light color
    70: '#A2AEBF',
    80: '#A8A8A8',
    100: '#EDF2F7',
    150: '#F9F9F9',
    170: '#F8F9FC',
    180: '#F8F8FB',
    190: '#EDF0F7',
    200: '#E2E8F0',
    220: '#EDEEF0',
    230: '#F2F2F2',
    250: '#E4E6EB',
    300: '#CBD5E0',
    350: '#4B5157',
    400: '#A0AEC0',
    450: '#DADADA',
    460: '#E0E0E0',
    470: '#D9DADB',
    500: '#718096',
    550: '#939699',
    600: '#4A5568',
    650: '#738095',
    700: '#2D3748',
    750: '#F6F6F6',
    800: '#1A202C',
    830: '#696969',
    840: '#BDBDBD',
    850: '#707070',
    860: '#828282',
    870: '#4F4F4F',
    900: '#171923',
    950: '#333333',
  },

  red: {
    40: '#FFF2EB',
    50: '#FFF5F5',
    100: '#FED7D7',
    200: '#FEB2B2',
    300: '#FC8181',
    400: '#F56565',
    420: '#EB5757',
    500: '#E53E3E',
    550: '#E0430F',
    600: '#C53030',
    700: '#9B2C2C',
    800: '#822727',
    900: '#63171B',
  },

  orange: {
    50: '#FFFAF0',
    100: '#FEEBC8',
    200: '#FBD38D',
    300: '#FFA500',
    400: '#ED8936',
    450: '#FF7D1F',
    500: '#DD6B20',
    550: '#FF6633',
    '550.transparent': '#FF66331A',
    600: '#C05621',
    700: '#9C4221',
    800: '#7B341E',
    900: '#652B19',
  },

  yellow: {
    50: '#FFFFF0',
    100: '#FEFCBF',
    150: '#F6C33F',
    200: '#FAF089',
    300: '#F6E05E',
    400: '#ECC94B',
    500: '#D69E2E',
    600: '#B7791F',
    650: '#FFBF441A',
    670: '#FFB11C',
    700: '#975A16',
    800: '#744210',
    900: '#5F370E',
  },

  green: {
    50: '#F0FFF4',
    100: '#C6F6D5',
    200: '#9AE6B4',
    300: '#68D391',
    400: '#48BB78',
    450: '#04C586',
    500: '#38A169',
    600: '#2F855A',
    700: '#276749',
    800: '#22543D',
    900: '#1C4532',
    950: '#27AE601A',
  },

  teal: {
    50: '#E6FFFA',
    100: '#B2F5EA',
    200: '#81E6D9',
    300: '#4FD1C5',
    400: '#38B2AC',
    500: '#319795',
    600: '#2C7A7B',
    700: '#285E61',
    800: '#234E52',
    900: '#1D4044',
  },

  blue: {
    50: '#ebf8ff',
    100: '#bee3f8',
    150: '#3D73EE',
    200: '#90cdf4',
    250: '#0092FF',
    300: '#63b3ed',
    350: '#5AA2F7',
    400: '#4299e1',
    500: '#3182ce',
    600: '#2b6cb0',
    650: '#497493',
    700: '#2c5282',
    800: '#2a4365',
    900: '#1A365D',
  },

  cyan: {
    50: '#EDFDFD',
    100: '#C4F1F9',
    200: '#9DECF9',
    300: '#76E4F7',
    400: '#0BC5EA',
    500: '#00B5D8',
    600: '#00A3C4',
    700: '#0987A0',
    800: '#086F83',
    900: '#065666',
  },

  purple: {
    50: '#FAF5FF',
    100: '#E9D8FD',
    200: '#D6BCFA',
    300: '#B794F4',
    400: '#9F7AEA',
    500: '#805AD5',
    600: '#6B46C1',
    700: '#553C9A',
    800: '#44337A',
    900: '#322659',
  },

  pink: {
    50: '#FFF5F7',
    100: '#FED7E2',
    200: '#FBB6CE',
    300: '#F687B3',
    400: '#ED64A6',
    500: '#D53F8C',
    600: '#B83280',
    700: '#97266D',
    800: '#702459',
    900: '#521B41',
  },

  linkedin: {
    50: '#E8F4F9',
    100: '#CFEDFB',
    200: '#9BDAF3',
    300: '#68C7EC',
    400: '#34B3E4',
    500: '#00A0DC',
    600: '#008CC9',
    700: '#0077B5',
    800: '#005E93',
    900: '#004471',
  },

  facebook: {
    50: '#E8F4F9',
    100: '#D9DEE9',
    200: '#B7C2DA',
    300: '#6482C0',
    400: '#4267B2',
    500: '#385898',
    600: '#314E89',
    700: '#29487D',
    800: '#223B67',
    900: '#1E355B',
  },

  messenger: {
    50: '#D0E6FF',
    100: '#B9DAFF',
    200: '#A2CDFF',
    300: '#7AB8FF',
    400: '#2E90FF',
    500: '#0078FF',
    600: '#0063D1',
    700: '#0052AC',
    800: '#003C7E',
    900: '#002C5C',
  },

  whatsapp: {
    50: '#dffeec',
    100: '#b9f5d0',
    200: '#90edb3',
    300: '#65e495',
    400: '#3cdd78',
    500: '#22c35e',
    600: '#179848',
    700: '#0c6c33',
    800: '#01421c',
    900: '#001803',
  },

  twitter: {
    50: '#E5F4FD',
    100: '#C8E9FB',
    200: '#A8DCFA',
    300: '#83CDF7',
    400: '#57BBF5',
    500: '#1DA1F2',
    600: '#1A94DA',
    700: '#1681BF',
    800: '#136B9E',
    900: '#0D4D71',
  },

  telegram: {
    50: '#E3F2F9',
    100: '#C5E4F3',
    200: '#A2D4EC',
    300: '#7AC1E4',
    400: '#47A9DA',
    500: '#0088CC',
    600: '#007AB8',
    700: '#006BA1',
    800: '#005885',
    900: '#003F5E',
  },
}

export default colors

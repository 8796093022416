import { useTranslations } from 'next-intl'
import { useAuthContext } from '@/hooks/useAuthContext'
import { useRouter } from 'next/router'
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  HStack,
  Icon,
  Text,
  useColorMode,
  useBreakpointValue,
  VStack,
} from '@chakra-ui/react'
import { SunMoonIcon } from '@/components/icons/SunMoonIcon'
import { MoonIcon } from '@/components/icons/MoonIcon'
import { SunOutlineIcon } from '@/components/icons/SunOutlineIcon'

export function NavColorMode() {
  const t = useTranslations()
  const { isAuthenticated, isFetched } = useAuthContext()
  const { colorMode, toggleColorMode } = useColorMode()
  const isMobile = useBreakpointValue(
    { base: true, md: false },
    { fallback: undefined }
  )

  const setColorMode = (mode: 'light' | 'dark') => {
    // Don't toggle color mode if this mode is already chosen
    if (mode === 'light' && colorMode === 'light') {
      return
    }
    if (mode === 'dark' && colorMode === 'dark') {
      return
    }
    toggleColorMode()
  }

  const DarkMode = () => (
    <HStack
      onClick={() => setColorMode('dark')}
      cursor="pointer"
      spacing={'6px'}
      width="100%"
      color={colorMode === 'dark' ? 'white' : 'gray.850'}
      fontWeight={colorMode === 'dark' ? 'bold' : 'normal'}
    >
      <Icon as={MoonIcon} />
      <Text>{t('common.dark')}</Text>
    </HStack>
  )

  const LightMode = () => (
    <HStack
      onClick={() => setColorMode('light')}
      cursor="pointer"
      spacing={'6px'}
      width="100%"
      color={colorMode === 'light' ? 'black' : 'gray.850'}
      fontWeight={colorMode === 'light' ? 'bold' : 'normal'}
    >
      <Icon as={SunOutlineIcon} />
      <Text>{t('common.light')}</Text>
    </HStack>
  )

  const DesktopView = () => (
    <Menu closeOnSelect={true}>
      <MenuButton
        as={Button}
        rightIcon={null}
        color={{ base: 'black', md: isAuthenticated ? 'white' : 'gray.850' }}
        bg="transparent"
        width="fit-content"
        padding="0"
        css={{
          '.chakra-button__icon': {
            transition: 'all 0.2s ease-in',
          },
        }}
        _hover={{
          bg: 'transparent',
          opacity: 0.7,
        }}
        _focus={{
          bg: 'transparent',
        }}
        _expanded={{
          bg: 'transparent',
          '.chakra-button__icon': {
            color: 'brand.600',
            transform: 'rotate(-180deg)',
          },
        }}
      >
        <Icon as={SunMoonIcon} />
      </MenuButton>
      <MenuList>
        <MenuItem>
          <DarkMode />
        </MenuItem>
        <MenuItem>
          <LightMode />
        </MenuItem>
      </MenuList>
    </Menu>
  )

  const MobileView = () => {
    return (
      <Accordion allowMultiple>
        <AccordionItem border="none">
          <AccordionButton>
            <Text width="100%" textAlign="left">
              {t('common.color-mode')}
            </Text>
            <AccordionIcon color="brand.600" />
          </AccordionButton>
          <AccordionPanel pb={4}>
            <VStack
              pl="16px"
              width="100%"
              alignItems="flex-start"
              spacing="16px"
            >
              <DarkMode />
              <LightMode />
            </VStack>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    )
  }

  return isMobile ? <MobileView /> : <DesktopView />
}

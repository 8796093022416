import type { GlobalStyleProps } from '@chakra-ui/theme-tools'
import { mode } from '@chakra-ui/theme-tools'

const Link = {
  baseStyle: (props: GlobalStyleProps) => ({
    color: mode('brand.600', 'brand.600')(props),
    _hover: {
      color: mode('brand.500', 'brand.500')(props),
    },
  }),
  variants: {
    black: (props: GlobalStyleProps) => ({
      color: mode('black', 'brand.600')(props),
      _hover: {
        color: mode('black', 'brand.500')(props),
      },
    }),
    blacklogo: (props: GlobalStyleProps) => ({
      color: mode('black', 'gray.250')(props),
      _hover: {
        color: mode('black', 'gray.250')(props),
      },
    }),
    whitelogo: (props: GlobalStyleProps) => ({
      color: mode('white', 'gray.250')(props),
      _hover: {
        color: mode('white', 'gray.250')(props),
      },
    }),
    nohover: () => ({
      color: 'inherit',
      _hover: {
        color: 'initial',
        textDecoration: 'none',
      },
    }),
    colored: (props: GlobalStyleProps) => ({
      textDecoration: 'underline',
      _hover: {
        color: props.color,
        textDecorationColor: props.color,
      },
    }),
  },
}

export default Link

import { useEffect } from 'react'
import { useRouter } from 'next/router'

/**
 * Tracks Google Tag Manager page_view event
 */
export const usePageViewTracking = () => {
  const router = useRouter()
  useEffect(() => {
    const handleRouteChange = (url: string) => {
      window &&
        window.dataLayer.push({
          event: 'page_view',
          page: url,
        })
    }
    if (process.env.NODE_ENV === 'production') {
      router.events.on('routeChangeComplete', handleRouteChange)
    }

    return () => {
      if (process.env.NODE_ENV === 'production') {
        router.events.off('routeChangeComplete', handleRouteChange)
      }
    }
  }, [router.events])
}

import type { GlobalStyleProps } from '@chakra-ui/theme-tools'
import { mode } from '@chakra-ui/theme-tools'

const Checkbox = {
  baseStyle: (props: GlobalStyleProps) => ({
    iconColor: 'brandBlue.50',
  }),
  variants: {
    rounded: {
      control: {
        borderRadius: 'base',
        border: '1px',
      },
    },
  },
}

export default Checkbox

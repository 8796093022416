import { useState } from 'react'
import { useToast } from '@chakra-ui/react'
import { useTranslations } from 'next-intl'
import { UseFormReset } from 'react-hook-form'
import { ZAPIER_SUBSCRIBE_HOOK } from '@/constants'

export type SubscribeFormValues = {
  newsletterEmail: string
}

export function useSubscribe(resetForm: UseFormReset<SubscribeFormValues>, handleSuccess?: () => void) {
  const [loading, setLoading] = useState(false)
  const toast = useToast()
  const t = useTranslations()

  const handleSubscribe = async ({ newsletterEmail }: SubscribeFormValues) => {
    toast.closeAll()
    setLoading(true)
    const formData = new FormData()
    formData.append('email', newsletterEmail)
    formData.append('label', 'compass')

    try {
      const data = {
        email: newsletterEmail,
        label: 'compass',
      }
      const req = await fetch(ZAPIER_SUBSCRIBE_HOOK, {
        body: JSON.stringify(data),
        method: 'POST',
      })
      const response = await req.json()
      setLoading(false)

      if (response?.status === 'success') {
        if (!!resetForm) {
          resetForm({ newsletterEmail: '' })
        }

        if  (handleSuccess) {
          handleSuccess()
        } else {
          toast({
            title: t('common.confirmation-email-sent'),
            status: 'success',
            duration: 5000,
            isClosable: true,
          })
        }
      } else {
        toast({
          title: t('common.error'),
          description: t('common.default-error'),
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      }
    } catch (e) {
      setLoading(false)
    }
  }

  return { handleSubscribe, loading }
}
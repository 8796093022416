import { Icon, IconProps } from '@chakra-ui/react'

export function PaymentsIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 16 13" fill="none" {...props}>
      <rect
        x="0.6"
        y="0.6"
        width="14.8"
        height="11.8"
        rx="2.4"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
      <circle cx="3.5" cy="3.5" r="0.5" fill="currentColor" />
      <circle cx="3.5" cy="6.5" r="0.5" fill="currentColor" />
      <circle cx="3.5" cy="9.5" r="0.5" fill="currentColor" />
      <rect x={5} y={3} width={8} height={1} fill="currentColor" />
      <rect x={5} y={6} width={8} height={1} fill="currentColor" />
      <rect x={5} y={9} width={8} height={1} fill="currentColor" />
    </Icon>
  )
}

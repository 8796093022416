import { useTranslations } from 'next-intl'
import {
  Box,
  Flex,
  Icon,
  IconButton,
  Collapse,
  useDisclosure,
  useBreakpointValue,
} from '@chakra-ui/react'

import { INavbar } from '@/types/strapi/strapiGlobal.interface'
import { ContainerBase } from '@/components/containers/ContainerBase'
import { CompassLogo } from '@/components/CompassLogo'
import { MenuIcon } from '@/components/icons/MenuIcon'
import { CloseIcon } from '@/components/icons/CloseIcon'
import { NavbarDesktop } from './NavbarDesktop'
import { NavbarMobile } from './NavbarMobile'
import { NavbarButtons } from './NavbarButtons'
import { CompassMiningIcon } from '../icons/CompassMiningIcon'

export function Navbar({ navigationLinks, renderCartButton = true }: INavbar) {
  const t = useTranslations()
  const { isOpen, onToggle } = useDisclosure()
  const iconOnly = useBreakpointValue(
    { base: false, lg: true, xl: false },
    { fallback: undefined }
  )

  return (
    <Box
      position="fixed"
      top="0"
      bg={'linear-gradient(270deg, #151517 0%, #4B5157 52.08%, #151517 100%)'}
      w={'full'}
      zIndex={10000}
    >
      <ContainerBase>
        <Flex
          flex={{ base: 1 }}
          minH={{ base: 0, lg: '82px' }}
          py={{ base: 1, lg: 2 }}
          align={'center'}
          justify={'space-between'}
        >
          {iconOnly && (
            <Icon
              as={CompassMiningIcon}
              color="white"
              width="50px"
              height="50px"
            />
          )}
          {!iconOnly && (
            <CompassLogo w={{ base: '250px', md: '290px' }} p={2} withSlogan />
          )}

          <Box display={{ base: 'flex', lg: 'none' }} mr={2}>
            <IconButton
              onClick={onToggle}
              size={'sm'}
              icon={
                isOpen ? <CloseIcon w={5} h={5} /> : <MenuIcon w={5} h={5} />
              }
              variant={'solid'}
              aria-label={t('buttons.toggle-navigation')}
            />
          </Box>

          <Flex display={{ base: 'none', lg: 'flex' }} ml={10}>
            <NavbarDesktop navigationLinks={navigationLinks} />
          </Flex>

          <Box display={{ base: 'none', lg: 'inline-flex' }}>
            <NavbarButtons
              menuCloseFn={onToggle}
              renderCartButton={renderCartButton}
            />
          </Box>
        </Flex>

        <Collapse in={isOpen} animateOpacity={false}>
          <NavbarMobile
            navigationLinks={navigationLinks}
            menuCloseFn={onToggle}
          >
            <NavbarButtons
              menuCloseFn={onToggle}
              renderCartButton={renderCartButton}
            />
          </NavbarMobile>
        </Collapse>
      </ContainerBase>
    </Box>
  )
}

import type { GlobalStyleProps } from '@chakra-ui/theme-tools'
import { mode } from '@chakra-ui/theme-tools'

const Divider = {
  baseStyle: (props: GlobalStyleProps) => ({
    backgroundColor: mode('#e2e8f0', 'gray.850')(props),
    opacity: 1,
  }),
}

export default Divider

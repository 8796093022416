import Box from './Box'
import Button from './Button'
import Checkbox from './Checkbox'
import Divider from './Divider'
import Input from './Input'
import Link from './Link'
import Table from './Table'
import Text from './Text'

export default {
  Box,
  Button,
  Checkbox,
  Divider,
  Input,
  Link,
  Table,
  Text,
}

import { ReactNode } from 'react'
import {
  Box,
  Container,
  ContainerProps,
  useStyleConfig,
} from '@chakra-ui/react'

interface IProps extends ContainerProps {
  children?: ReactNode
  variant?: string
}

export function ContainerBase({
  children,
  variant = 'transparent',
  ...rest
}: IProps) {
  const styles = useStyleConfig('Box', { variant })
  return (
    <Container
      maxW={{
        sm: 'container.sm',
        md: 'container.md',
        lg: 'container.lg',
        xl: 'container.xl',
        xxl: 'container.xxl',
      }}
      px={4}
      {...rest}
    >
      <Box __css={styles}>{children}</Box>
    </Container>
  )
}

import { useForm } from 'react-hook-form'
import { useSubscribe, SubscribeFormValues } from '@/hooks/useSubscribe'
import {
  Divider,
  Stack,
  Flex,
  Box,
  IconProps,
  Spacer,
  Heading,
  Text,
  Link,
  List,
  ListItem,
  useColorModeValue,
  Input,
  Button,
  HStack,
} from '@chakra-ui/react'
import ReactMarkdown from 'react-markdown'
import NextLink from 'next/link'

import { ContainerBase } from '@/components/containers/ContainerBase'
import { ILink } from '@/types/link.interface'
import {
  ICompanyContacts,
  IFooter,
  IFooterColumn,
  ISubscribeForm,
} from '@/types/strapi/strapiGlobal.interface'
import { ArrowForwardIcon } from '@chakra-ui/icons'
import { CompassLogo } from '@/components/CompassLogo'
import { useTranslations } from 'next-intl'
import { TwitterIcon } from '@/components/icons/TwitterIcon'
import { LinkedinIcon } from '@/components/icons/LinkedinIcon'
import { MessageIcon } from '@/components/icons/MessageIcon'
import { EMAIL_PATTERN } from '@/utils/inputPattern'

type Props = {
  companyContactsData?: ICompanyContacts
  subscribeFormData?: ISubscribeForm
  footerData: IFooter
  isHomePage?: boolean
}

export function Footer({
  companyContactsData = {},
  subscribeFormData = {},
  footerData,
  isHomePage,
}: Props) {
  const t = useTranslations()

  const { descriptionText, copyRight, columns, smallLinks } = footerData
  const linkColor = useColorModeValue('black', 'white')
  const {
    handleSubmit,
    reset,
    register,
    formState: { errors },
  } = useForm<SubscribeFormValues>()
  const { handleSubscribe, loading } = useSubscribe(reset)

  return (
    <Box py={8} bg={useColorModeValue('white', 'darkModeBG')} fontSize="16px">
      <ContainerBase>
        {subscribeFormData && !isHomePage && (
          <>
            <Stack
              flex="1"
              direction={{ base: 'column', md: 'row' }}
              spacing="4"
              alignItems="center"
              justifyContent="space-between"
              mt={{ base: '20vw', md: '43px' }}
              mb={{ base: '', md: '32px' }}
            >
              <Box
                flexBasis={{ base: '', xl: '654px' }}
                fontSize={{ base: '14px', md: '16px' }}
              >
                {subscribeFormData.subTitle && (
                  <Text variant="withDarkMode" fontWeight="bold">
                    {subscribeFormData.subTitle}
                  </Text>
                )}
                {subscribeFormData.description && (
                  <Box color="#939699">
                    <ReactMarkdown>
                      {subscribeFormData.description}
                    </ReactMarkdown>
                  </Box>
                )}
              </Box>
              <Flex
                flex="1"
                bg="#EDEEF0"
                padding="7px"
                borderRadius="10px"
                maxWidth={{ md: '50%' }}
                borderWidth="2px"
                borderColor={
                  !!errors?.newsletterEmail ? 'red.500' : 'transparent'
                }
              >
                <Input
                  {...register('newsletterEmail', {
                    required: t('forms.errors.is-required'),
                    validate: {
                      format: (val) => {
                        return EMAIL_PATTERN.test(val)
                      },
                    },
                  })}
                  placeholder={t('placeholders.enter-email')}
                  fontSize='16px'
                  border="none"
                  color="black"
                  bg="transparent"
                  _placeholder={{
                    color: 'gray.550',
                  }}
                  _focusVisible={{ outline: 'none' }}
                />
                <Button
                  onClick={handleSubmit(handleSubscribe)}
                  isLoading={loading}
                  loadingText={t('buttons.subscribe')}
                  variant="brand"
                  width={{ base: '', md: '132px' }}
                  height='40px'
                >
                  <Text fontSize={{ base: '12px', md: '14px' }}>
                    {t('buttons.subscribe')} <ArrowForwardIcon />
                  </Text>
                </Button>
              </Flex>
            </Stack>
            <Divider my={{ base: '5vw', md: 'unset' }} />
          </>
        )}
        <Stack direction={{ base: 'column', lg: 'row' }} mt={8} mb={12}>
          <Box w={{ base: 'full', lg: '50%' }} mb={{ base: 8, lg: 0 }}>
            <Box width="180px">
              <CompassLogo
                color={useColorModeValue('black', 'white')}
                _hover={{
                  color: 'inherit',
                }}
                w={{ base: '150px' }}
                p={2}
              />
            </Box>
            {descriptionText && (
              <ReactMarkdown>{descriptionText}</ReactMarkdown>
            )}
            {companyContactsData && (
              <>
                <Divider my={4} />
                <Box lineHeight={{ base: '21px', md: '24px' }}>
                  {companyContactsData.title && (
                    <Text variant="withDarkMode" fontWeight="bold">
                      {companyContactsData.title}
                    </Text>
                  )}
                  {companyContactsData.address && (
                    <Text variant="withDarkMode">
                      {companyContactsData.address}
                    </Text>
                  )}

                  <HStack>
                    {companyContactsData.contact && (
                      <Text
                        variant="withDarkMode"
                        as="a"
                        href={`mailto:${companyContactsData.contact}`}
                      >
                        {companyContactsData.contact}
                      </Text>
                    )}
                    {/* Currently, phone is not in use, maybe needed at a later date? */}
                    {/* {companyContactsData.phone && (
                      <>
                        <Divider height="20px" orientation="vertical" />
                        <Text variant="withDarkMode">
                          {companyContactsData.phone}
                        </Text>
                      </>
                    )} */}
                  </HStack>
                </Box>
              </>
            )}
          </Box>
          <Spacer />
          <Flex
            direction={{ base: 'column', md: 'row' }}
            wrap={'wrap'}
            gap={{ base: 8, md: 16 }}
            maxW={{ base: '100%', md: '50%' }}
          >
            {columns.map((column: IFooterColumn) => (
              <Box key={`footer_column_${column.id}`}>
                <Heading size={'sm'} fontSize='16px' mb={3}>
                  {column.title}
                </Heading>
                <List spacing={3}>
                  {column.links.map((link: ILink) => (
                    <ListItem key={`footer_column_link_${link.id}`}>
                      <NextLink href={link.url} passHref>
                        <Link
                          isExternal={link.newTab}
                          fontWeight={400}
                          color={linkColor}
                          _hover={{
                            color: 'brand.600',
                          }}
                        >
                          <HStack alignItems="baseline">
                            {link.iconName && (
                              <IconLink iconName={link.iconName} w={4} h={4} />
                            )}
                            <Text>{link.text}</Text>
                          </HStack>
                        </Link>
                      </NextLink>
                    </ListItem>
                  ))}
                </List>
              </Box>
            ))}
          </Flex>
        </Stack>
        <Stack direction={{ base: 'column', lg: 'row' }}>
          <Flex gap={{ base: 4, xl: 8 }} wrap={'wrap'}>
            {smallLinks.map((link: ILink) => (
              <NextLink href={link.url} passHref key={`small_link_${link.id}`}>
                <Link
                  isExternal={link.newTab}
                  fontSize={'12px'}
                  fontWeight={400}
                  color={linkColor}
                  textDecoration={'underline'}
                  _hover={{
                    color: 'brand.600',
                  }}
                >
                  {link.text}
                </Link>
              </NextLink>
            ))}
          </Flex>
          <Spacer />
          <Text fontSize={'12px'}>{copyRight}</Text>
        </Stack>
      </ContainerBase>
    </Box>
  )
}

interface IIconLinkProps extends IconProps {
  iconName: string
}

function IconLink({ iconName, ...rest }: IIconLinkProps) {
  if (iconName === 'twitter') {
    return <TwitterIcon {...rest} />
  }
  if (iconName === 'linkedin') {
    return <LinkedinIcon {...rest} />
  }

  if (iconName === 'contact') {
    return <MessageIcon transform={'translateY(1px)'} {...rest} />
  }

  return <></>
}

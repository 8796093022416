import React, { createContext, ReactNode } from 'react'
import { useUser, UserProvider } from '@auth0/nextjs-auth0/client'
import { ApolloProvider, useQuery } from '@apollo/client'

import { amILoggedIn } from '@/api/auth'
import { AUTH0_ENABLED } from '@/constants'
import { useApollo } from '@/utils/apolloClient'

type Props = {
  children: ReactNode
  pageProps?: any
}

type UserProps = {
  isFetched?: boolean
  isAuthenticated?: boolean
  isEmailVerified?: boolean
  refetchAuthStatus?: () => void
}

export const authContext = createContext<UserProps>({})

/**
 * Function for Authentication provider in development env
 * (when Auth0 is disabled)
 * See @AuthProviderDev
 */
function useProvideAuthDev(): UserProps {
  const [user, setUser] = React.useState<null | UserProps>(null)
  const { error, loading, data, refetch } = useQuery(amILoggedIn)

  React.useEffect(() => {
    if (loading) {
      setUser({
        isFetched: false,
        isAuthenticated: false,
      })
    }

    if (data && !loading) {
      if (data.amILoggedin) {
        setUser({
          isFetched: true,
          isAuthenticated: true,
        })
      } else {
        setUser({
          isFetched: true,
          isAuthenticated: false,
        })
      }
    }
  }, [error, data, loading])

  return {
    isFetched: user?.isFetched,
    isAuthenticated: user?.isAuthenticated,
    isEmailVerified: true,
    refetchAuthStatus: refetch,
  }
}

/**
 * Function for Authentication provider in production env
 * (when Auth0 is enabled)
 * See @AuthProviderProd
 */
function useProvideAuthProd(): UserProps {
  const { isLoading, user, error } = useUser()

  if (isLoading) {
    return {
      isFetched: false,
      isAuthenticated: false,
      isEmailVerified: false,
    }
  }
  if (user) {
    const isEmailVerified = user.email_verified || false
    return {
      isFetched: true,
      isAuthenticated: true,
      isEmailVerified,
    }
  }
  if (error) {
    console.error(error)
  }
  return {
    isFetched: true,
    isAuthenticated: false,
    isEmailVerified: false,
  }
}

/**
 * Authentication Provider for Development environment only
 * (doesn't use Auth0. Uses `amILoggedIn` query instead)
 */
function AuthProviderDev({ children }: Props) {
  const auth = useProvideAuthDev()
  return <authContext.Provider value={auth}>{children}</authContext.Provider>
}

/**
 * Authentication Provider for Production build.
 * It starts only when Auth0 is enabled.
 * Auth0 hook `useUser` is called to see if user is authenticated
 */
function AuthProviderProd({ children }: Props) {
  const auth = useProvideAuthProd()
  return <authContext.Provider value={auth}>{children}</authContext.Provider>
}

export const useAuthContext = () => {
  return React.useContext(authContext)
}

export function AuthorizedApolloProvider({ children, pageProps }: Props) {
  const apolloClient = useApollo(pageProps)

  if (AUTH0_ENABLED) {
    return (
      <UserProvider>
        <ApolloProvider client={apolloClient}>
          <AuthProviderProd>{children}</AuthProviderProd>
        </ApolloProvider>
      </UserProvider>
    )
  }

  return (
    <ApolloProvider client={apolloClient}>
      <AuthProviderDev>{children}</AuthProviderDev>
    </ApolloProvider>
  )
}

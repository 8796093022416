import React from 'react'
import Head from 'next/head'
import { useTranslations } from 'next-intl'
import { SITE_URL } from '@/constants'

interface SeoProps {
  title?: string
  description?: string
  image?: string
  url?: string
  children?: React.ReactNode
}

const SeoHead = function({
  title = '',
  description = '',
  image = `${SITE_URL}/img/og-compass.jpeg`,
  url = SITE_URL,
  children = null,
}: SeoProps) {
  const t = useTranslations('common')
  const computedTitle = title || t('seo.title')
  const computedDescription = description || t('seo.description')

  return (
    <Head>
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <title>{computedTitle}</title>
      <link rel="icon" href="/favicon.ico" />
      <meta name="title" content={computedTitle} key="title" />
      <meta
        name="description"
        content={computedDescription}
        key="description"
      />
      <meta name="image" content={image} key="image" />
      <meta name="url" content={url} key="url" />
      <meta
        property="og:site_name"
        content="Compass Mining Inc."
        key="siteName"
      />
      <meta property="og:title" content={computedTitle} key="ogTitle" />
      <meta
        property="og:description"
        content={computedDescription}
        key="ogDescription"
      />
      <meta property="og:url" content={url} key="ogUrl" />
      <meta property="og:image" content={image} key="ogImage" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={computedTitle} key="twTitle" />
      <meta
        name="twitter:description"
        content={computedDescription}
        key="twDescription"
      />
      <meta name="twitter:url" content={url} key="twUrl" />
      <meta name="twitter:image" content={image} key="twImage" />
      {children}
    </Head>
  )
}

export default SeoHead

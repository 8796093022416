import type { GlobalStyleProps } from '@chakra-ui/theme-tools'
import { mode } from '@chakra-ui/theme-tools'

const Box = {
  variants: {
    card: (props: GlobalStyleProps) => ({
      bg: mode('white', 'black.100')(props),
    }),
    white: (props: GlobalStyleProps) => ({
      bg: mode('white', 'black.200')(props),
      color: mode('black', 'gray.250')(props),
    }),
    gray: (props: GlobalStyleProps) => ({
      bg: mode('#F6F6F6', 'black.100')(props),
    }),
    dark: (props: GlobalStyleProps) => ({
      color: mode('white', 'gray.250')(props),
    }),
    'white-gradient': (props: GlobalStyleProps) => ({
      bg: mode(
        'white',
        'linear-gradient(270deg, #151517 0%, #4B5157 50%, #151517 100%)'
      )(props),
    }),
    'ghost-white': (props: GlobalStyleProps) => ({
      bg: mode('#F8F8FB', 'black.200')(props),
      color: mode('black', 'gray.250')(props),
    }),
    customScroll: (props: GlobalStyleProps) => ({
      bg: mode('white', 'black.100')(props),
      color: mode('black', 'gray.250')(props),
      padding: {
        base: '0 20px 20px 24px',
        md: '',
        xl: '0 0 24px 24px',
      },
      position: 'relative',
      overflow: 'auto',
      '&::-webkit-scrollbar': {
        width: '6px',
      },
      '&::-webkit-scrollbar-track': {
        background: mode('gray.50', 'gray.850')(props),
        width: '19px',
      },
      '&::-webkit-scrollbar-thumb': {
        background: 'gray.450',
        borderRadius: '40px',
      },
    }),
  },
  defaultProps: {
    variant: 'white',
  },
}

export default Box

import { Icon, IconProps } from '@chakra-ui/react'

export function MessageIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 30 30" {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.646 0.442383H3.3539C1.75256 0.442383 0.45694 1.75256 0.45694 3.3539L0.442383 29.5575L6.26541 23.7345H26.646C28.2474 23.7345 29.5575 22.4243 29.5575 20.823V3.3539C29.5575 1.75256 28.2474 0.442383 26.646 0.442383ZM23.7345 17.9114H6.26542V14.9999H23.7345V17.9114ZM23.7345 13.5442H6.26542V10.6326H23.7345V13.5442ZM23.7345 9.1769H6.26542V6.26539H23.7345V9.1769Z"
      />
    </Icon>
  )
}

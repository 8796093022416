import { gql } from '@apollo/client'

const INVOICE_WITH_LINE_ITEMS_NODE = gql`
  fragment INVOICE_WITH_LINE_ITEMS_NODE on InvoicesWithLineItem {
    invoiceId
    generatedOn
    dueDate
    paidDate
    status
    adjustedTotalAmount
    paidWithAutopay
    appliedWalletCredits
    appliedCompassCredits
    subTotalPreCreditAmount
    lineItemCountHardware
    lineItemCountHosting
    lineItemCountProtection
    lineItemCountTotal
    subTotalFees
    subTotalLateFeesHosting
    subTotalTaxes
    subscriptionType
    paymentMethod
    lineItemsHosting {
      invoiceId
      lineItemId
      orderId
      amountDue
      endDate
      facilityCountryCode
      facilityDisplayName
      lateFee
      machineModel
      tax
      startDate
      invoiceMiners {
        minerId
        workerId
        hashrate
        powerConsumptionWatts
      }
    }
    lineItemsHardware {
      invoiceId
      lineItemId
      orderId
      amountDue
      facilityCountryCode
      facilityDisplayName
      hardwareInstallmentNumber
      hardwareTotalNumberInstallments
      hardwareType
      machineModel
      tax
      invoiceMiners {
        minerId
        workerId
        hashrate
        powerConsumptionWatts
      }
    }
    lineItemsProtection {
      invoiceId
      lineItemId
      orderId
      amountDue
      endDate
      facilityCountryCode
      facilityDisplayName
      machineModel
      protectionCoverageAmount
      startDate
      tax
      invoiceMiners {
        minerId
        workerId
        hashrate
        powerConsumptionWatts
      }
    }
  }
`

export const BINLookup = gql`
  query BINLookup($binNumber: String!) {
    binLookup(binNumber: $binNumber)
  }
`

export const createCreditInvoice = gql`
  mutation CreateCreditInvoice(
    $creditsAmount: BigFloat!
    $minersId: [UUID]!
    $months: Int!
    $paymentAmount: BigFloat!
  ) {
    createCreditInvoice(
      input: {
        minersId: $minersId
        months: $months
        creditsAmount: $creditsAmount
        paymentAmount: $paymentAmount
      }
    ) {
      uuid
    }
  }
`

export const getInvoiceStatus = gql`
  query getInvoiceStatus($invoiceId: [UUID]!) {
    getInvoiceStatus(invoiceId: $invoiceId) {
      nodes {
        changeEvent
        invoiceStatus
      }
    }
  }
`

export const getOutstandingInvoices = gql`
  ${INVOICE_WITH_LINE_ITEMS_NODE}
  query outstandingInvoices(
    $offset: Int
    $pageLimit: Int = 100
    $filter: [InvoicesWithLineItemFilter!]
    $status: [InvoiceStatus!] = [NOT_PAID, LATE, PENDING]
  ) {
    allInvoicesWithLineItems(
      orderBy: DUE_DATE_ASC
      first: $pageLimit
      offset: $offset
      filter: { status: { in: $status }, and: $filter }
    ) {
      nodes {
        ...INVOICE_WITH_LINE_ITEMS_NODE
      }
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
`

export const getPaidInvoices = gql`
  ${INVOICE_WITH_LINE_ITEMS_NODE}
  query paidInvoices(
    $offset: Int
    $pageLimit: Int = 10
    $filter: [InvoicesWithLineItemFilter!]
  ) {
    allInvoicesWithLineItems(
      first: $pageLimit
      offset: $offset
      orderBy: PAID_DATE_DESC
      filter: { status: { in: [PAID, EXPIRED] }, and: $filter }
    ) {
      nodes {
        ...INVOICE_WITH_LINE_ITEMS_NODE
      }
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
    }
  }
`

export const getInvoiceById = gql`
  ${INVOICE_WITH_LINE_ITEMS_NODE}
  query invoiceById($id: [UUID!]) {
    allInvoicesWithLineItems(
      filter: { invoiceId: { in: $id } }
      orderBy: DUE_DATE_ASC
    ) {
      nodes {
        ...INVOICE_WITH_LINE_ITEMS_NODE
      }
    }
  }
`

export const getInvoicesByIds = gql`
  ${INVOICE_WITH_LINE_ITEMS_NODE}
  query invoicesByIds($ids: [UUID!]) {
    allInvoicesWithLineItems(
      filter: { invoiceId: { in: $ids } }
      orderBy: DUE_DATE_ASC
    ) {
      nodes {
        ...INVOICE_WITH_LINE_ITEMS_NODE
      }
    }
  }
`

export const getInvoicesSubAndFeeTotals = gql`
  query getInvoicesSubAndFeeTotals(
    $invoiceIds: [UUID!]
    $paymentMethod: PaymentMethodType
    $binNumber: String
    $credits: BigFloat
  ) {
    getInvoicesSubAndFeeTotals(
      invoiceIds: $invoiceIds
      paymentMethod: $paymentMethod
      binNumber: $binNumber
      credits: $credits
    ) {
      nodes {
        invoiceSubtotalDue
        invoiceTotalFees
        invoiceTotalWithFees
      }
    }
  }
`

export const processCoinbasePayment = gql`
  mutation processCoinbasePayment($invoiceIds: [UUID]!, $redirectUrl: String!) {
    createCryptoCharge(
      input: { invoiceOrOrderIds: $invoiceIds, redirectUrl: $redirectUrl }
    ) {
      results {
        compassRedirectUrl
        chargeCode
      }
    }
  }
`

export const processLightningPayment = gql`
  mutation processLightningPayment($invoiceIds: [UUID]!, $successUrl: String!) {
    createLightningCharge(
      input: { invoiceOrOrderIds: $invoiceIds, successUrl: $successUrl }
    ) {
      results {
        compassRedirectUrl
        chargeStatus
      }
    }
  }
`

export const createAchOrCardPayment = gql`
  mutation createAchOrCardPayment(
    $accountNumberHash: String!
    $addressOne: String
    $addressTwo: String
    $binNumber: String
    $city: String
    $country: String
    $fullNameHash: String!
    $firstName: String
    $lastName: String
    $invoiceOrOrderIds: [UUID]!
    $paymentMethodType: PaymentMethodType!
    $paymentToken: String!
    $phone: String
    $postalCode: String
    $state: String
  ) {
    createAchOrCardPayment(
      input: {
        accountNumberHash: $accountNumberHash
        addressOne: $addressOne
        addressTwo: $addressTwo
        binNumber: $binNumber
        city: $city
        country: $country
        firstName: $firstName
        fullNameHash: $fullNameHash
        lastName: $lastName
        invoiceOrOrderIds: $invoiceOrOrderIds
        paymentMethodType: $paymentMethodType
        paymentToken: $paymentToken
        phone: $phone
        postalCode: $postalCode
        state: $state
      }
    ) {
      clientMutationId
      string
    }
  }
`

export const applyCompassCreditsToInvoice = gql`
  mutation applyCompassCreditsToInvoice(
    $creditsAmount: Float!
    $invoiceIds: [UUID]!
  ) {
    applyCompassCreditsToInvoice(
      input: { invoiceIds: $invoiceIds, creditsAmount: $creditsAmount }
    ) {
      invoiceUnpaidIds
    }
  }
`
export const applyCompassWalletToInvoice = gql`
  mutation applyCompassWalletToInvoice(
    $walletAmount: Float!
    $invoiceIds: [UUID]!
  ) {
    applyCompassWalletToInvoice(
      input: { invoiceIds: $invoiceIds, walletAmount: $walletAmount }
    ) {
      boolean
      clientMutationId
    }
  }
`

export const exportInvoiceOrdersCsv = gql`
  query exportInvoiceOrders($invoiceOrderIds: [UUID!], $type: ExportDocType) {
    exportInvoicesOrders(invoiceOrderIds: $invoiceOrderIds, type: $type)
  }
`

export const prePaidCreditsOffer = gql`
  query PrepaidCreditsOffer {
    prepaidCreditsOffer {
      nodes {
        availableCompassCredits
        discountPerMonths {
          discount
          months
        }
        totalMonthlyFees {
          fees
          minersId
        }
      }
    }
  }
`

import { useState, useEffect } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { useRouter } from 'next/router'
import { useToast } from '@chakra-ui/react'
import { useTranslations } from 'next-intl'

import {
  getRequiredAgreements,
  signAgreementOnPopup,
} from '@/api/agreements'
import { REQUIRE_AGREEMENTS_SIGN } from '@/constants'
import {
  IAgreementLockout,
  IRequiredAgreements,
} from '@/types/agreements.interface'

export function useAgreemetsLockout(): IAgreementLockout {
  const router = useRouter()
  const toast = useToast()
  const t = useTranslations()
  const [isLockoutActive, setLockoutActive] = useState<boolean>(false)

  const { data: requiredAgreements } = useQuery<IRequiredAgreements>(
    getRequiredAgreements,
    {
      skip: (parseInt(REQUIRE_AGREEMENTS_SIGN || '0') === 0)
    }
  )

  const onAgreementFetch = async () => {
    if (requiredAgreements?.getRequiredAgreements?.nodes.length) {
      setLockoutActive(true)
    } else {
      setLockoutActive(false)
    }
  }

  useEffect(() => {
    onAgreementFetch()
  }, [requiredAgreements])

  useEffect(() => {
    const allowedPages = ['/dashboard/settings']
    if (!allowedPages.includes(router.pathname) && isLockoutActive) {
      setLockoutActive(true)
    }
  }, [router, isLockoutActive])

  const [signAgreementOnPopupMutation, { loading: signAgreementLoading }] =
    useMutation(signAgreementOnPopup, {
      variables: {
        agreementId:
          // prepare for signing only first agreement. Second agreement will be refetched after signing first agreement
          requiredAgreements?.getRequiredAgreements?.nodes[0]?.agreementId,
      },

      refetchQueries: [getRequiredAgreements],
      onCompleted: (data) => {
        if (data?.popupAgreementSigning?.boolean) {
          setLockoutActive(false)
          return toast({
            title: t('dashboard.agreement.successMessage'),
            status: 'success',
          })
        }
        toast({
          title: t('dashboard.agreement.errorMessage'),
          status: 'error',
        })
      },
      onError: (error) => {
        console.error(error)
        toast({
          title: t('dashboard.agreement.errorMessage'),
          status: 'error',
        })
      },
    })

  // enable this hook only when feature flag is enabled
  if (parseInt(REQUIRE_AGREEMENTS_SIGN || '0') === 0) {
    return {
      isLockoutActive: false,
      agreement: null,
      signAgreement: () => {
        return
      },
      signAgreementLoading: false,
      agreementsTotal: null
    }
  }

  return {
    isLockoutActive,
    agreement: requiredAgreements?.getRequiredAgreements?.nodes[0] || null,
    signAgreement: signAgreementOnPopupMutation,
    signAgreementLoading,
    agreementsTotal: requiredAgreements?.getRequiredAgreements?.nodes.length || 0
  }
}

import { Icon, IconProps } from '@chakra-ui/react'

export function ArrowDownIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 16 12" fill="none" {...props}>
      <path
        d="M8.81088 10.8764C8.41175 11.4295 7.58825 11.4295 7.18911 10.8764L0.483769 1.58521C0.00647069 0.923843 0.479047 3.75618e-07 1.29465 4.46921e-07L14.7053 1.61932e-06C15.521 1.69062e-06 15.9935 0.923843 15.5162 1.58521L8.81088 10.8764Z"
        fill="currentColor"
      />
    </Icon>
  )
}

import { useEffect } from 'react'
import {
  Box,
  Button,
  Stack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalFooter,
  Text,
  Link,
  useDisclosure,
  useColorModeValue,
} from '@chakra-ui/react'
import dynamic from 'next/dynamic'
import { useTranslations } from 'next-intl'

import { SUPPORT_URL } from '@/constants'
import { IAgreementLockout } from '@/types/agreements.interface'
import { InsertDriveFileIcon } from '@/components/icons/InsertDriveFileIcon'
import { Loading } from '@/components/Loading'
const PdfModal = dynamic(
  () => import('@/features/checkout/Agreements/PdfModal'),
  {
    ssr: false,
  }
)

export const AgreementLockout = ({
  isLockoutActive,
  agreement,
  agreementsTotal,
  signAgreement,
  signAgreementLoading,
}: IAgreementLockout) => {
  const t = useTranslations()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const titleColor = useColorModeValue('gray.900', 'gray.200')
  const subtitleColor = useColorModeValue('gray.500', 'gray.300')

  useEffect(() => {
    if (isLockoutActive) {
      onOpen()
    }
  }, [isLockoutActive])

  return agreement ? (
    <>
      <Modal
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={onClose}
        size="3xl"
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          {signAgreementLoading ? (
            <Loading />
          ) : (
            <>
              <ModalBody px={5} pt={5}>
                <Stack
                  direction={{ base: 'column', md: 'row' }}
                  align={'start'}
                  justify={'start'}
                  gap={4}
                >
                  <Stack
                    width={12}
                    height={12}
                    rounded="full"
                    bg="orange.550.transparent"
                    justifyContent="center"
                    align="center"
                  >
                    <InsertDriveFileIcon
                      color="orange.550"
                      width={6}
                      height={6}
                    />
                  </Stack>
                  <Box>
                    <Text
                      fontSize={'xl'}
                      mb={2}
                      color={titleColor}
                      fontWeight={500}
                    >
                      {t.rich('dashboard.agreement.title', {
                        type: agreement.type,
                        number: agreementsTotal,
                        span: (children) => (
                          <Text as="span" fontWeight={'bold'} textTransform={'capitalize'}>
                            {children}
                          </Text>
                        )
                      })}
                    </Text>
                    <Text textColor={subtitleColor} fontSize={'sm'}>
                      {t.rich('dashboard.agreement.subtitle', {
                        link: (children) => (
                          <Link
                            isExternal
                            href={agreement.uri}
                            textColor={subtitleColor}
                            textDecoration="underline"
                          >
                            {children}
                          </Link>
                        ),
                      })}
                    </Text>
                  </Box>
                </Stack>
              </ModalBody>
              <ModalFooter>
                <Button
                  size={'sm'}
                  as={Link}
                  isExternal
                  href={SUPPORT_URL}
                  _hover={{
                    textDecoration: 'none',
                  }}
                  mr="4"
                >
                  {t('buttons.contact-support')}
                </Button>

                <PdfModal
                  filePath={agreement.uri}
                  handleAgree={signAgreement}
                  isChecked={false}
                  trigger={
                    <Button
                      size={'sm'}
                      variant="brand"
                      colorScheme="brand"
                      autoFocus
                    >
                      {t('common.continue')}
                    </Button>
                  }
                />
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </>
  ) : null
}

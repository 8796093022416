import { gql } from '@apollo/client'

export const alterUserFullNameMutation = gql`
  mutation AlterUserFullName($newName: String) {
    alterUserFullName(input: { newName: $newName }) {
      clientMutationId
      boolean
    }
  }
`

export const alterUserBusinessNameMutation = gql`
  mutation AlterUserBusinessName($newOrgName: String) {
    alterUserOrgName(input: { newOrgName: $newOrgName }) {
      clientMutationId
      boolean
    }
  }
`

export const alterUserTaxIdMutation = gql`
  mutation AlterUserTaxId($newTaxId: String) {
    alterUserTaxId(input: { newTaxId: $newTaxId }) {
      clientMutationId
      boolean
    }
  }
`

export const createBeneficiaryMutation = gql`
  mutation CreateBeneficiary(
    $address: String
    $email: String!
    $fullName: String!
    $relationship: BeneficiaryRelationship!
  ) {
    createBeneficiary(
      input: {
        address: $address
        email: $email
        fullName: $fullName
        relationship: $relationship
      }
    ) {
      boolean
    }
  }
`

export const alterBeneficiaryMutation = gql`
  mutation AlterBeneficiary(
    $id: UUID!
    $address: String
    $email: String!
    $fullName: String!
    $relationship: BeneficiaryRelationship!
  ) {
    alterBeneficiary(
      input: {
        beneficiaryId: $id
        beneficiaryAddress: $address
        beneficiaryEmail: $email
        beneficiaryFullName: $fullName
        beneficiaryRelationship: $relationship
      }
    ) {
      boolean
    }
  }
`

export const alterPasswordDataMutation = gql`
  mutation AlterPasswordData {
    alterUserPassword(input: {}) {
      boolean
    }
  }
`

export const removeMFA = gql`
  mutation RemoveMfa {
    disableMfa(input: {}) {
      clientMutationId
      boolean
    }
  }
`

export const getAllBeneficiaries = gql`
  query GetAllBeneficiaries {
    getAllBeneficiaries {
      nodes {
        address
        email
        fullName
        id
        phoneNumber
        relationship
      }
    }
  }
`
export const deleteBeneficiaryMutation = gql`
  mutation DeleteBeneficiary($id: UUID!) {
    deleteBeneficiary(input: { _beneficiaryId: $id }) {
      boolean
    }
  }
`

export const isUserKycQuery = gql`
  query IsUserKyc {
    amIKyced
  }
`

import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Button,
  Link,
  Icon,
  Image,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'
import NextLink from 'next/link'
import { ArrowForwardIcon } from '@chakra-ui/icons'
import { CircleRightArrowIcon } from '../../components/icons/CircleRightArrowIcon'
import { useTranslations } from 'next-intl'
import { VStack, Flex, Box, useBreakpointValue } from '@chakra-ui/react'
import { CompassWalletIcon } from '@/components/icons/CompassWalletIcon'

const CompassWalletModal = ({ isOpen, onCloseModal }: any) => {
  const t = useTranslations()
  const image = useBreakpointValue({
    base: '/img/wallet-ipad-image-mobile.png',
    md: '/img/wallet-ipad-image.png',
  })

  return (
    <Modal
      isOpen={isOpen}
      onClose={onCloseModal}
      size={'3xl'}
      closeOnOverlayClick={false}
      isCentered
    >
      <ModalOverlay />
      <ModalContent rounded={'16px'} pt={12}>
        <ModalHeader pb={'0'}>
          <Flex pb={'4'} justify={'center'}>
            <Box
              width={{ base: '35px', md: '34px' }}
              height={{ base: '35px', md: '30px' }}
            >
              <Icon
                as={CompassWalletIcon}
                color="brand.600"
                width="100%"
                height="100%"
              />
            </Box>
            <Text
              color={useColorModeValue('gray.950', 'white')}
              fontSize={'4xl'}
              textAlign={'center'}
              lineHeight={'42px'}
              ml={'3'}
            >
              {t('dashboard.menu.compass-wallet')}
            </Text>
          </Flex>

          <Text
            fontWeight={'500'}
            color={useColorModeValue('black', 'white')}
            textAlign={'center'}
            fontSize={'16px'}
            px={{ base: '0', md: '10' }}
          >
            {t('dashboard.compass-wallet.modal-description')}
          </Text>
          <VStack align={'center'}>
            <NextLink href={'/dashboard/verification'} passHref>
              <Link
                variant="nohover"
                mt={{ base: '25px', md: '21px', xl: '31px' }}
              >
                <Button
                  bg="black"
                  color="white"
                  _hover={{
                    bg: 'black',
                    opacity: 0.8,
                  }}
                  rounded={'8px'}
                  onClick={onCloseModal}
                  rightIcon={
                    <CircleRightArrowIcon
                      boxSize={{ base: '20px', md: '21px' }}
                      color="brand.600"
                    />
                  }
                  width={{ base: '100%', md: 'auto', xl: '184px' }}
                  height={{ base: '40px', md: '40px', xl: '42px' }}
                  fontSize={{ base: '14px', md: '14px' }}
                >
                  {t('compass-wallet.get-verified-now')}
                </Button>
              </Link>
            </NextLink>
            <NextLink href={'/compass-wallet'} passHref>
              <Link
                _hover={{
                  textDecoration: 'none',
                }}
                fontWeight={'500'}
                fontSize={'md'}
                target={'_blank'}
              >
                {t('buttons.learn-more')}
                <Icon as={ArrowForwardIcon} />
              </Link>
            </NextLink>
          </VStack>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody p={'0'}>
          <Flex justify={'center'}>
            <Image
              src={image}
              alt="image"
              width={{ base: '100%', xl: '94%' }}
            />
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default CompassWalletModal

import NextLink from 'next/link'
import { Button, ButtonProps, LinkProps, MenuItem } from '@chakra-ui/react'
import { useTranslations } from 'next-intl'

import { useAuthContext } from '@/hooks/useAuthContext'

interface IProps extends ButtonProps {
  label: string
  href: string
}

export function AccountMenuItem({ label, href, ...rest }: IProps) {
  const t = useTranslations()

  const { isAuthenticated, isFetched } = useAuthContext()

  if (isAuthenticated && isFetched) {
    return (
      <MenuItem>
        <NextLink href={href} passHref>
          <Button
            as="a"
            fontWeight="500"
            variant="transparent"
            width="100%"
            justifyContent="flex-start"
            padding={0}
            height={7}
            {...rest}
          >
            {label}
          </Button>
        </NextLink>
      </MenuItem>
    )
  }

  return null
}

import { ReactNode } from 'react'
import { Box, Grid, Text, useStyleConfig } from '@chakra-ui/react'
import { useTranslations } from 'next-intl'
import { CompassLogo } from '@/components/CompassLogo'
import { Menu } from './Menu'
import { Header } from './Header'

type Props = {
  isPageBlurred: Boolean
  children: ReactNode
}

export function Desktop({ isPageBlurred = false, children }: Props) {
  return (
    <Grid gridTemplateColumns={{ md: '180px auto', xl: '300px auto' }}>
      <Sidebar />
      <Box
        maxWidth={{ base: 'calc(100vw - 180px)', lg: 'calc(100vw - 300px)' }}
        overflow="auto"
        filter={isPageBlurred ? 'blur(3px)' : 'none'}
      >
        <Header />
        <Box>{children}</Box>
      </Box>
    </Grid>
  )
}

const Sidebar = () => {
  const t = useTranslations()
  const styles = useStyleConfig('Box', { variant: 'white' })

  return (
    <Box
      __css={styles}
      minH="100vh"
      boxShadow="inset -1px 0px 0px #EDF0F7"
      padding={{ md: '20px 10px', xl: '50px 20px' }}
      zIndex={2}
    >
      <Box>
        <Box w={{ md: '130px', xl: '203px' }}>
          <CompassLogo variant="blacklogo" />
        </Box>
        <Text
          textAlign="center"
          fontSize={{ md: '8px', xl: '12px' }}
          mt={{ md: '3px', xl: '7px' }}
          textTransform="uppercase"
        >
          {t('dashboard.menu.command-center')}
        </Text>
      </Box>
      <Menu />
    </Box>
  )
}

import { Global } from '@emotion/react'

const Fonts = () => (
  <Global
    styles={`
      /* latin */
      @font-face {
        font-family: 'Roobert Medium';
        font-display: swap;
        src: url('/fonts/Roobert-Medium.woff2') format('woff2'), url('/fonts/Roobert-Medium.woff') format('woff');
      }
      /* latin */
      @font-face {
        font-family: 'Roobert Light';
        font-display: swap;
        src: url('/fonts/Roobert-Light.woff2') format('woff2'), url('/fonts/Roobert-Light.woff') format('woff');
      }
      /* latin */
      @font-face {
        font-family: 'Inter Black';
        font-display: swap;
        src: url('/fonts/Inter-Black.ttf') format('truetype');
      }
      /* latin */
      @font-face {
        font-family: 'Inter Bold';
        font-display: swap;
        src: url('/fonts/Inter-Bold.ttf') format('truetype');
      }
      /* latin */
      @font-face {
        font-family: 'Inter Extra Bold';
        font-display: swap;
        src: url('/fonts/Inter-ExtraBold.ttf') format('truetype');
      }
      /* latin */
      @font-face {
        font-family: 'Inter Extra Light';
        font-display: swap;
        src: url('/fonts/Inter-ExtraLight.ttf') format('truetype');
      }
      /* latin */
      @font-face {
        font-family: 'Inter Light';
        font-display: swap;
        src: url('/fonts/Inter-Light.ttf') format('truetype');
      }
      /* latin */
      @font-face {
        font-family: 'Inter Medium';
        font-display: swap;
        src: url('/fonts/Inter-Medium.ttf') format('truetype');
      }
      /* latin */
      @font-face {
        font-family: 'Inter';
        font-display: swap;
        src: url('/fonts/Inter-Regular.ttf') format('truetype');
      }
      /* latin */
      @font-face {
        font-family: 'Inter SemiBold';
        font-display: swap;
        src: url('/fonts/Inter-SemiBold.ttf') format('truetype');
      }
      /* latin */
      @font-face {
        font-family: 'Inter Thin';
        font-display: swap;
        src: url('/fonts/Inter-Thin.ttf') format('truetype');
      }
    `}
  />
)

export default Fonts

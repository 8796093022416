import NextLink from 'next/link'
import { Button, ButtonProps, MenuButton } from '@chakra-ui/react'
import { ChevronDownIcon } from '@chakra-ui/icons'
import { useTranslations } from 'next-intl'

import { useAuthContext } from '@/hooks/useAuthContext'

export function AccountMenuButton({ ...rest }: ButtonProps) {
  const t = useTranslations()

  const { isAuthenticated, isFetched } = useAuthContext()

  if (isAuthenticated && isFetched) {
    return (
      <MenuButton as={Button} variant="brand">
        {t('common.account')}&nbsp;&nbsp;
        <ChevronDownIcon />
      </MenuButton>
    )
  }

  return (
    <NextLink href={'/sign-in'} passHref>
      <MenuButton
        as={Button}
        variant="brand"
        width={{ base: '', md: '116px' }}
        {...rest}
      >
        {t('buttons.sign-in')}
      </MenuButton>
    </NextLink>
  )
}

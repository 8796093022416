import { useEffect, useState } from 'react'
import { useTranslations } from 'next-intl'
import { Box, Button, Flex, Link, useColorModeValue } from '@chakra-ui/react'
import { PRIVACY_POLICY } from '@/constants'
import { ContainerBase } from './containers/ContainerBase'

export const CookiesDisclaimer = () => {
  const t = useTranslations()
  const [isAccepted, setIsAccepted] = useState(false)
  const bg = useColorModeValue('gray.50', 'black.200')

  useEffect(() => {
    if (typeof window !== 'undefined') {
      let _isAccepted = false
      _isAccepted = Boolean(localStorage.getItem('cookies-disclaimer'))
      setIsAccepted(_isAccepted)
    }
  }, [t])

  const onAccept = () => {
    localStorage.setItem('cookies-disclaimer', 'true')
    setIsAccepted(true)
  }

  if (isAccepted) return null

  return (
    <Box
      position="fixed"
      zIndex="10000"
      bottom="0"
      left="0"
      width="100vw"
      height={{ base: 'auto', md: '85px' }}
      padding={{ base: '16px', md: '26px 96px' }}
      bg={bg}
    >
      <ContainerBase>
        <Flex flexDir="row" flexWrap="wrap" gridGap="16px">
          <Box fontSize="12px" maxWidth={{ base: 'none', md: '85%' }}>
            {t.rich('common.cookies-disclaimer', {
              link: (children) => {
                return (
                  <Link href={PRIVACY_POLICY} color={'brand.600'} isExternal>
                    {children}
                  </Link>
                )
              },
            })}
          </Box>
          <Button
            onClick={onAccept}
            variant="black"
            width={{ base: '100%', md: 'initial' }}
          >
            {t('common.accept')}
          </Button>
        </Flex>
      </ContainerBase>
    </Box>
  )
}

import { Icon, IconProps } from '@chakra-ui/react'

export function LinkedinIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 15 15" {...props}>
      <path
        d="M3.49161 4.81972H0.480336C0.346691 4.81972 0.2384 4.9283 0.2384 5.06219V14.7574C0.2384 14.8914 0.346691 14.9999 0.480336 14.9999H3.49161C3.62525 14.9999 3.73354 14.8914 3.73354 14.7574V5.06219C3.73354 4.9283 3.62525 4.81972 3.49161 4.81972Z"
        fill="currentColor"
      />
      <path
        d="M1.98706 0C0.891386 0 0 0.89238 0 1.98926C0 3.08662 0.891386 3.97934 1.98706 3.97934C3.08187 3.97934 3.97253 3.08657 3.97253 1.98926C3.97258 0.89238 3.08187 0 1.98706 0Z"
        fill="currentColor"
      />
      <path
        d="M11.151 4.57879C9.94157 4.57879 9.04752 5.09986 8.50525 5.69192V5.06223C8.50525 4.92833 8.39696 4.81976 8.26331 4.81976H5.37949C5.24585 4.81976 5.13756 4.92833 5.13756 5.06223V14.7575C5.13756 14.8914 5.24585 15 5.37949 15H8.38418C8.51783 15 8.62612 14.8914 8.62612 14.7575V9.96058C8.62612 8.34414 9.06421 7.7144 10.1885 7.7144C11.413 7.7144 11.5103 8.72394 11.5103 10.0437V14.7575C11.5103 14.8915 11.6186 15 11.7523 15H14.7581C14.8917 15 15 14.8915 15 14.7575V9.43951C15 7.03592 14.5427 4.57879 11.151 4.57879Z"
        fill="currentColor"
      />
    </Icon>
  )
}

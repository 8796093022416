import React from 'react'

import { useTranslations } from 'next-intl'
import NextLink from 'next/link'

import { getUserMiningPools } from '@/api/miningPool'
import { useQuery } from '@apollo/client'

import { Loading } from '@/components/Loading'
import { IMiningPoolNode } from '@/types/miningPool.interface'
import { setMiningPoolImage } from '../../layouts/DashboardLayout/constants'

import {
  Box,
  Flex,
  Icon,
  Image,
  Stack,
  Text,
  VStack,
  useColorModeValue,
} from '@chakra-ui/react'

const MiningPoolMenuItem = () => {
  const t = useTranslations()

  const { data, loading } = useQuery(getUserMiningPools)

  const darkModeWhite = useColorModeValue('white', 'gray.900')
  const darkModeBlack = useColorModeValue('gray.900', 'white')

  if (loading) return <Loading />

  const defaultPool = data?.userMiningPools?.nodes.find(
    (pool: IMiningPoolNode) => pool?.miningPoolAccountDefaultPool
  )

  if (defaultPool) {
    return (
      <Stack w="full" shadow={'lg'}>
        <NextLink href="/dashboard/mining-pool">
          <Box
            bg={darkModeWhite}
            borderRadius={'lg'}
            padding={2}
            cursor="pointer"
            border={'1px solid'}
            py="5"
            mt={{ base: 0, md: 3 }}
          >
            <Flex align={'center'}>
              <Image
                src={setMiningPoolImage(defaultPool?.miningPoolName)}
                alt={defaultPool?.miningPoolName}
                w={9}
                h={9}
                mr={{ base: 5, md: 3 }}
              />
              <Flex flexDirection={'column'}>
                <Text color={darkModeBlack} fontSize="16px" fontWeight="600">
                  {defaultPool?.miningPoolName}
                </Text>
                <Text
                  color={darkModeBlack}
                  fontSize="15px"
                  fontWeight="400"
                  textOverflow={'ellipsis'}
                  overflow={'hidden'}
                  whiteSpace={'nowrap'}
                  w={{ base: '36', md: '20', xl: '36' }}
                >
                  {defaultPool.miningPoolNickName ||
                    defaultPool.miningPoolAccountUserName}
                </Text>
              </Flex>
            </Flex>
          </Box>
        </NextLink>
      </Stack>
    )
  }

  return (
    <VStack spacing={'40'}>
      <Box bg={darkModeBlack} borderRadius="lg" padding={2} cursor="pointer">
        <Text
          color={darkModeWhite}
          fontSize="12px"
          fontWeight="600"
          align={'center'}
        >
          {t('dashboard.mining-pool.no-default-pool-set')}
        </Text>
        <Text
          color={darkModeWhite}
          fontSize="12px"
          fontWeight="400"
          align={'center'}
        >
          {t('dashboard.mining-pool.please-set-pool-for-new-hardware')}
        </Text>
        <NextLink href="/dashboard/mining-pool">
          <Text
            color="brand.600"
            fontSize="12px"
            fontWeight="400"
            align={'center'}
            display={'flex'}
            alignItems={'center'}
            justifyContent={'center'}
            _hover={{
              color: 'brand.500',
              cursor: 'pointer',
            }}
          >
            {t('dashboard.mining-pool.set-up-my-pool')}

            <Icon
              viewBox="0 0 24 24"
              w="16px"
              h="16px"
              ml="4px"
              transform="rotate(180deg)"
              justifyContent={'center'}
              alignItems={'center'}
              _hover={{
                color: 'brand.500',
                cursor: 'pointer',
              }}
            >
              <path
                fill="currentColor"
                d="M9.29,16.29,4.59,11.59a1,1,0,0,1,0-1.42l4.7-4.7a1,1,0,0,1,1.42,1.42L7.41,10H20a1,1,0,0,1,0,2H7.41l2.3,2.29a1,1,0,0,1-1.42,1.42Z"
              />
            </Icon>
          </Text>
        </NextLink>
      </Box>
    </VStack>
  )
}

export default MiningPoolMenuItem

import { useApolloClient, useLazyQuery } from '@apollo/client'
import { getAllCarts } from '@/api/checkout'

export function useCart() {
  const client = useApolloClient()
  const [getCart] = useLazyQuery(getAllCarts)
  const cart = client.readQuery({ query: getAllCarts })

  return { getCart, cart }
}

import {
  ApolloClient,
  HttpLink,
  from,
  DefaultOptions,
  NormalizedCacheObject,
  ApolloLink,
  InMemoryCache,
} from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { setContext } from '@apollo/client/link/context'
import { useMemo } from 'react'
import merge from 'deepmerge'
import isEqual from 'lodash/isEqual'

import { nonHostedTaxRateVar } from '../apolloCache/cartCache'

import { API_URL, STRAPI_API_URL, AUTH0_ENABLED } from '@/constants'
import { getAuthToken, removeAuthToken } from './authToken'

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__'

let apolloClient: ApolloClient<NormalizedCacheObject> | undefined

let apolloToken = ''

const compassEndpoint = new HttpLink({
  uri: API_URL,
})

const strapiEndpoint = new HttpLink({
  uri: STRAPI_API_URL,
})

// apollo query or mutation will call Compass graphql endpoint,
// to call Strapi graphql endpoint, pass the context:
// useQuery(yourQuery, { context: { clientName: strapi }})
const httpLinks = ApolloLink.split(
  (operation) => operation.getContext().clientName === 'strapi',
  strapiEndpoint,
  compassEndpoint //if above
)

// Display GraphQL error in console and in terminal.
// This error handling should be added to Sentry too
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    )
  if (networkError) console.log(`[Network error]: ${networkError}`)
})

// Retry the last request in case of networkError caused by authentication errors (works only for Compass API)
const retryLink = onError(({ networkError, operation, forward }) => {
  if (
    networkError &&
    'statusCode' in networkError &&
    operation.getContext().clientName !== 'strapi'
  ) {
    switch (networkError.statusCode) {
      // token expired error. redirect user to the sign in page
      case 401:
        removeAuthToken()
        if (typeof window !== 'undefined') {
          console.log('retryLink: redirecting to /sign-in')
          setTimeout(() => {
            window.location.href = '/sign-in'
          }, 1000)
        }
        break
      // jwt malformed error. delete authorization header and retry requests
      case 403:
        removeAuthToken()
        const { headers } = operation.getContext()
        delete headers.authorization
        operation.setContext({
          headers,
        })
        console.log(
          `authentication error: ${networkError.statusCode}, ${networkError.message}. Retrying`
        )
        return forward(operation)
      default:
        console.log(
          `network error: ${networkError.statusCode}, ${networkError.message}. No need to retry`
        )
    }
  }
})

// Add Authorization headers to Postgraphile-related requests.
// Token behavior is different for development (postgrahile auth) and production (Auth0 auth) environments
const authLink = setContext(async (_, { headers, clientName }) => {
  if (typeof window !== 'undefined' && !apolloToken) {
    if (AUTH0_ENABLED) {
      // get Auth0 authentication token from api function
      const res = await fetch('/api/session')
      if (res.ok) {
        const ses = await res.json()
        apolloToken = ses?.accessToken ?? ''
      } else {
        const errorResponse = await res.json()
        if (errorResponse?.error === 'ERR_EXPIRED_ACCESS_TOKEN') {
          const currentRoute = window.location.pathname
          window.location.href = `/api/auth/login?returnTo=${currentRoute}`
        }
      }
    } else {
      // get the authentication token from local storage if it exists
      apolloToken = getAuthToken() || ''
    }
  }

  // return the headers to the context so httpLink can read them.
  // Skip setting authorization header for Strapi
  if (apolloToken && clientName !== 'strapi') {
    return {
      headers: {
        ...headers,
        authorization: `Bearer ${apolloToken}`,
      },
    }
  }
  return headers
})

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
  },
  mutate: {
    errorPolicy: 'all',
  },
}

export const cache = new InMemoryCache({
  // TypePolicies can be set to customize how the cache interacts with specific types in your schema
  typePolicies: {
    Query: {
      // fields property enables you to customize the behavior of individual cached fields.
      fields: {
        nonHostedTaxRate: {
          // A read function can alter the value of a field (in cache) before it is returned to your application. (https://www.apollographql.com/docs/react/caching/cache-field-behavior/)
          read() {
            return nonHostedTaxRateVar()
          },
        },
      },
    },
  },
})

function createApolloClient() {
  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: from([retryLink, errorLink, authLink, httpLinks]),
    cache: new InMemoryCache(),
    defaultOptions,
  })
}

// Cache normalizing for Server-Side Rendering in Nextjs
export function initializeApollo(
  initialState: NormalizedCacheObject | null = null
) {
  const _apolloClient = apolloClient ?? createApolloClient()

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract()

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = merge(initialState, existingCache, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter((d) =>
          sourceArray.every((s) => !isEqual(d, s))
        ),
      ],
    })

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data)
  }

  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient

  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient

  return _apolloClient
}

// Apollo initialization on server.
// Use this function when you need to call Apollo requests in getServerSideProps (SSR mode)
export function addApolloState(
  client: ApolloClient<NormalizedCacheObject>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  pageProps: any
) {
  if (pageProps?.props) {
    pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract()
  }

  return pageProps
}

// Apollo initialization on client.
// Use this function when you need to read Apollo cache directly from memory
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useApollo(pageProps: any) {
  const state = pageProps[APOLLO_STATE_PROP_NAME]
  const store = useMemo(() => initializeApollo(state), [state])
  return store
}

import { Icon, IconProps } from '@chakra-ui/react'

export function TwitterIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 18 15" {...props}>
      <path
        d="M18 1.77577C17.3306 2.07692 16.6174 2.27654 15.8737 2.37346C16.6387 1.905 17.2226 1.16885 17.4971 0.281538C16.7839 0.717692 15.9964 1.02577 15.1571 1.19769C14.4799 0.458077 13.5146 0 12.4616 0C10.4186 0 8.77387 1.70077 8.77387 3.78577C8.77387 4.08577 8.79862 4.37423 8.85938 4.64885C5.7915 4.49538 3.07687 2.98731 1.25325 0.69C0.934875 1.25654 0.748125 1.905 0.748125 2.60308C0.748125 3.91385 1.40625 5.07577 2.38725 5.74846C1.79437 5.73692 1.21275 5.56038 0.72 5.28231C0.72 5.29385 0.72 5.30885 0.72 5.32385C0.72 7.16308 1.99912 8.69077 3.6765 9.04269C3.37612 9.12692 3.04875 9.16731 2.709 9.16731C2.47275 9.16731 2.23425 9.15346 2.01037 9.10269C2.4885 10.6015 3.84525 11.7035 5.4585 11.7392C4.203 12.7465 2.60888 13.3535 0.883125 13.3535C0.5805 13.3535 0.29025 13.3396 0 13.3015C1.63462 14.3827 3.57187 15 5.661 15C12.4515 15 16.164 9.23077 16.164 4.23C16.164 4.06269 16.1584 3.90115 16.1505 3.74077C16.8829 3.20769 17.4982 2.54192 18 1.77577Z"
        fill="currentColor"
      />
    </Icon>
  )
}

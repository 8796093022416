import NextLink from 'next/link'
import {
  Box,
  Stack,
  Link,
  Text,
  Flex,
  Popover,
  PopoverTrigger,
  PopoverContent,
  useColorModeValue,
} from '@chakra-ui/react'

import { ChevronRightIcon } from '@/components/icons/ChevronRightIcon'
import { INavItem } from '@/types/navItem.interface'

type Props = {
  navigationLinks: Array<INavItem>
}

export function NavbarDesktop({ navigationLinks }: Props) {
  const popoverContentBgColor = useColorModeValue('white', 'gray.600')

  return (
    <Flex direction={'row'} columnGap={4} flexWrap="wrap">
      {navigationLinks.map((navItem) => (
        <Box key={`nav_desktop_${navItem.id}`}>
          {navItem.submenu.length ? (
            <Popover trigger={'hover'} placement={'bottom-start'}>
              <PopoverTrigger>
                <Box
                  cursor="pointer"
                  p={2}
                  fontSize="14px"
                  fontWeight={500}
                  color={'white'}
                  _hover={{
                    textDecoration: 'none',
                    color: 'brand.600',
                  }}
                >
                  {navItem.text}
                </Box>
              </PopoverTrigger>

              {navItem.submenu && (
                <PopoverContent
                  border={0}
                  boxShadow={'xl'}
                  bg={popoverContentBgColor}
                  p={4}
                  rounded={'xl'}
                  minW={'sm'}
                >
                  <Stack>
                    {navItem.submenu.map((child) => (
                      <NavbarDesktopSubmenu
                        key={`nav_desktop_submenu_${child.text}`}
                        {...child}
                      />
                    ))}
                  </Stack>
                </PopoverContent>
              )}
            </Popover>
          ) : (
            <Flex>
              <NextLink href={navItem.url ?? '#'} passHref>
                <Link
                  isExternal={navItem.newTab}
                  p={2}
                  fontSize="14px"
                  fontWeight={500}
                  color={'white'}
                  _hover={{
                    textDecoration: 'none',
                    color: 'brand.600',
                  }}
                >
                  {navItem.text}
                </Link>
              </NextLink>
            </Flex>
          )}
        </Box>
      ))}
    </Flex>
  )
}

function NavbarDesktopSubmenu({ text, url, newTab }: INavItem) {
  return (
    <NextLink href={url} passHref>
      <Link
        isExternal={newTab}
        role={'group'}
        display={'block'}
        p={2}
        rounded={'md'}
        color={useColorModeValue('black', 'white')}
        _hover={{ bg: 'brand.50' }}
      >
        <Stack direction={'row'} align={'center'}>
          <Box>
            <Text
              transition={'all .3s ease'}
              _groupHover={{ color: 'brand.600' }}
              fontWeight={500}
            >
              {text}
            </Text>
          </Box>
          <Flex
            transition={'all .3s ease'}
            transform={'translateX(-10px)'}
            opacity={0}
            _groupHover={{ opacity: '100%', transform: 'translateX(0)' }}
            justify={'flex-end'}
            align={'center'}
            flex={1}
          >
            <ChevronRightIcon color={'brand.600'} w={5} h={5} />
          </Flex>
        </Stack>
      </Link>
    </NextLink>
  )
}

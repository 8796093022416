import NextLink from 'next/link'
import { useEffect } from 'react'
import { useAuthContext } from '@/hooks/useAuthContext'
import { useCart } from '@/hooks/useCart'
import { Button } from '@chakra-ui/react'
import { CartsIcon } from '@/components/icons/CartsIcon'

export function CartButton() {
  const { isAuthenticated, isFetched } = useAuthContext()
  const { getCart, cart } = useCart()

  useEffect(() => {
    if (isAuthenticated && isFetched) {
      getCart()
    }
  }, [isAuthenticated, isFetched])

  if (!(isAuthenticated && isFetched)) return null

  // TODO: Remove this once the cart is fixed to return null when empty
  const isCartNotEmpty =
    cart?.allCarts?.nodes?.length &&
    cart.allCarts.nodes[0] !== null &&
    cart.allCarts.nodes[0].cartItemProductId !== null

  return (
    <NextLink href="/checkout" passHref>
      <Button
        as="a"
        visibility={isCartNotEmpty ? 'visible' : 'hidden'}
        leftIcon={<CartsIcon fontSize="25px" />}
        bg={{base: "white", md: "transparent"}}
        color={{base: "black", md: "white"}}
        width="63px"
        _hover={{ color: 'black', bg: 'white' }}
        fontSize={'16px'}
        padding="12px"
      >
        {cart?.allCarts?.totalCount || ''}
      </Button>
    </NextLink>
  )
}

import { Navbar } from '@/components/Navbar'
import { Box } from '@chakra-ui/react'
import { ReactNode } from 'react'
import { Footer } from '@/components/Footer'
import { IStrapiGlobalData } from '@/types/strapi/strapiGlobal.interface'

type Props = {
  children: ReactNode
  global: IStrapiGlobalData
  isHomePage?: boolean
}

export function DefaultLayout({ global, children, isHomePage }: Props) {
  const { navbar, footer, companyContacts, subscribeForm } = global
  return (
    <>
      <Navbar navigationLinks={navbar.navigationLinks} />
      <main>
        <Box pt={{ base: '53px', lg: '82px' }} mb={{ base: 12, md: 16 }}>
          {children}
        </Box>
      </main>
      <Footer
        subscribeFormData={subscribeForm}
        companyContactsData={companyContacts}
        footerData={footer}
        isHomePage={isHomePage}
      />
    </>
  )
}

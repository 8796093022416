import { useTranslations } from 'next-intl'
import { useQuery } from '@apollo/client'
import { isUserKycQuery } from '@/api/dashboard'
import {
  Button,
  Center,
  Flex,
  HStack,
  Icon,
  Image,
  VStack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'
import NextLink from 'next/link'
import { ArrowLeftIcon } from '@/components/icons/ArrowLeftIcon'

export const KycBox = ({ onClose }: { onClose?: () => void }) => {
  const t = useTranslations()
  const { data: dataKyc, loading: loadingKYC } = useQuery(isUserKycQuery, {
    fetchPolicy: 'cache-first',
  })
  const textColor = useColorModeValue('gray.850', 'white')
  const blackBg = useColorModeValue('black', 'white')
  const whiteText = useColorModeValue('white', 'black')

  if (loadingKYC) return null

  return (
    <Center width="100%">
      <VStack
        width={{ base: '252px', md: '100%' }}
        height={'232px'}
        boxShadow="0 4px 4px rgba(0,0,0,.25)"
        borderRadius="6px"
        border="1px solid black"
        padding="15px 34px"
        textAlign="center"
        spacing="14px"
      >
        {!dataKyc?.amIKyced ? (
          <>
            <Image src="/img/kyc-banner.png" boxSize={'72px'} />
            <Text color={textColor}>{t('common.access-all-features')}</Text>
            <NextLink href="/verification" passHref>
              <Button
                as="a"
                width="fit-content"
                bg={blackBg}
                color={whiteText}
                _hover={{ bg: blackBg, opacity: 0.8 }}
                onClick={() => onClose && onClose()}
              >
                <Flex 
                  flexDirection='row' 
                  flexWrap='wrap' 
                  align='center' 
                  justify='center' 
                  py={{base: '2px', xl: '0'}}
                  gridGap={{ base: '2px', xl: '10px'}}
                >
                  <Text fontSize={{base: 'xs', xl: '16px'}}>{t('common.get-verified-now')}</Text>
                  <Icon
                    as={ArrowLeftIcon}
                    boxSize={{ base: '15px', xl: "20px"}}
                    borderRadius="full"
                    bg="brand.600"
                    color="white"
                    padding={1}
                    transform="rotate(180deg)"
                  />
                </Flex>
              </Button>
            </NextLink>
          </>
        ) : (
          <>
            <Image src="/img/kyc-verified.png" width={'84px'} height={'72px'} />
            <Text color={textColor} fontSize="16px">
              {t('common.you-are-a-verified-seller')}
            </Text>
            <NextLink href="/dashboard/miners/hosted?sale_view=1" passHref>
              <Button
                as="a"
                width="100%"
                bg="linear-gradient(295.37deg, #FF531C 13.13%, #FF9A44 85.35%)"
                color="white"
                _hover={{ bg: 'black', opacity: 0.8 }}
                onClick={() => onClose && onClose()}
              >
                <Text fontSize="14px" fontWeight="bold">
                  {t('common.start-selling')}
                </Text>
              </Button>
            </NextLink>
          </>
        )}
      </VStack>
    </Center>
  )
}

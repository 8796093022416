import { gql } from '@apollo/client'

export const loginMutation = gql`
  mutation Authenticate($email: String!, $password: String!) {
    authenticate(input: { email: $email, password: $password }) {
      jwt
    }
  }
`

export const amILoggedIn = gql`
  query AmILoggedin {
    amILoggedin
  }
`

export const getCurrentUser = gql`
  query CurrentUser {
    currentUser {
      nodes {
        userBusinessName
        userEmail
        userFullName
        userTaxId
      }
    }
  }
`

export const renewJwtMutation = gql`
  mutation ReAuth {
    reauth(input: {}) {
      jwt
    }
  }
`

export const registerMutation = gql`
  mutation Register($email: String!, $password: String!) {
    register(input: { email: $email, password: $password }) {
      boolean
    }
  }
`

export const alterUserEmail = gql`
  mutation AlterUserEmail($newEmail: String!) {
    alterUserEmail(input: { newEmail: $newEmail }) {
      boolean
    }
  }
`

export const confirmAlterEmail = gql`
  mutation ConfirmAlterEmail {
    confirmAlterUserEmail(input: {}) {
      boolean
    }
  }
`

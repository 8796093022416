import { Icon, IconProps } from '@chakra-ui/react'

export function SunOutlineIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 16 16" fill="none" {...props}>
      <path
        d="M13.3333 10.2066L15.54 7.99996L13.3333 5.79329V2.66663H10.2066L7.99996 0.459961L5.79329 2.66663H2.66663V5.79329L0.459961 7.99996L2.66663 10.2066V13.3333H5.79329L7.99996 15.54L10.2066 13.3333H13.3333V10.2066ZM7.99996 12C5.79329 12 3.99996 10.2066 3.99996 7.99996C3.99996 5.79329 5.79329 3.99996 7.99996 3.99996C10.2066 3.99996 12 5.79329 12 7.99996C12 10.2066 10.2066 12 7.99996 12Z"
        fill="currentColor"
      />
    </Icon>
  )
}

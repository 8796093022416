import { gql } from '@apollo/client'

export const addPoolAccountMutation = gql`
  mutation addPoolAccount(
    $miningPoolId: String!
    $miningPoolsAccountsDefaultPool: Boolean!
    $nickName: String!
    $poolName: String!
    $stratum1: String!
    $stratum2: String!
    $stratum3: String!
    $userName: String!
    $watcherUri: String!
  ) {
    addPoolAccount(
      input: {
        miningPoolId: $miningPoolId
        nickName: $nickName
        miningPoolsAccountsDefaultPool: $miningPoolsAccountsDefaultPool
        stratum1: $stratum1
        stratum2: $stratum2
        stratum3: $stratum3
        userName: $userName
        watcherUri: $watcherUri
        poolName: $poolName
      }
    ) {
      clientMutationId
      boolean
    }
  }
`

export const getAllMiningPools = gql`
  query getAllMiningPoolsList {
    getMiningPoolsList {
      nodes {
        miningPoolIcon
        miningPoolId
        miningPoolName
        miningPoolStratum1
        miningPoolStratum2
        miningPoolStratum3
        display
      }
    }
  }
`

export const deleteMiningPoolAccount = gql`
  mutation deletePoolAccount($miningPoolAccountId: UUID!) {
    deletePoolAccount(input: { miningPoolAccountId: $miningPoolAccountId }) {
      boolean
      clientMutationId
    }
  }
`

export const getUserMiningPools = gql`
  query getUserMiningPoolQuery {
    userMiningPools {
      nodes {
        miningPoolAccountDefaultPool
        miningPoolAccountId
        miningPoolAccountUserName
        miningPoolAccountWatcherUri
        miningPoolIcon
        miningPoolId
        miningPoolName
        miningPoolNickName
        miningPoolStratum1
        miningPoolStratum2
        miningPoolStratum3
      }
    }
  }
`

export const updateMiningPoolNickname = gql`
  mutation updateMiningPoolNickname(
    $miningPoolAccountId: UUID!
    $poolNickname: String!
  ) {
    updateMiningPoolNickname(
      input: {
        miningPoolAccountId: $miningPoolAccountId
        poolNickname: $poolNickname
      }
    ) {
      clientMutationId
      boolean
    }
  }
`

export const updateMiningPoolUsername = gql`
  mutation updateMiningPoolUsername(
    $miningPoolAccountId: UUID!
    $poolUsername: String!
  ) {
    updateMiningPoolUsername(
      input: {
        miningPoolAccountId: $miningPoolAccountId
        poolUsername: $poolUsername
      }
    ) {
      clientMutationId
      boolean
    }
  }
`

export const updateMiningPoolWatcherUri = gql`
  mutation updateMiningPoolWatcherUri(
    $miningPoolAccountId: UUID!
    $watcherUri: String!
  ) {
    updateMiningPoolWatcherUri(
      input: {
        miningPoolAccountId: $miningPoolAccountId
        watcherUri: $watcherUri
      }
    ) {
      clientMutationId
      boolean
    }
  }
`

export const updateDefaultMiningPool = gql`
  mutation updateDefaultMiningPool($miningPoolAccountId: UUID!) {
    updateDefaultMiningPool(
      input: { miningPoolAccountId: $miningPoolAccountId }
    ) {
      boolean
      clientMutationId
    }
  }
`

export const updateMiningPoolStratums = gql`
  mutation updateMiningPoolStratums(
    $miningPoolAccountId: UUID!
    $poolStratum1: String
    $poolStratum2: String
    $poolStratum3: String
  ) {
    updateMiningPoolStratums(
      input: {
        miningPoolAccountId: $miningPoolAccountId
        poolStratum1: $poolStratum1
        poolStratum2: $poolStratum2
        poolStratum3: $poolStratum3
      }
    ) {
      clientMutationId
      boolean
    }
  }
`

import { Icon, IconProps } from '@chakra-ui/react'

export function QuestionIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 48 48" fill="none" {...props}>
      <circle cx={24} cy={24} r={24} fill="#EDEEF0" />
      <path
        d="M24 29.4194C23.0023 29.4194 22.1935 30.2281 22.1935 31.2258C22.1935 32.2235 23.0023 33.0323 24 33.0323C24.9977 33.0323 25.8065 32.2235 25.8065 31.2258C25.8065 30.2281 24.9977 29.4194 24 29.4194ZM24.4948 27.871H23.4626C23.035 27.871 22.6884 27.5243 22.6884 27.0968V27.0722C22.6884 22.5339 27.6845 22.9677 27.6845 20.1426C27.6845 18.8513 26.5386 17.5484 23.9787 17.5484C22.0985 17.5484 21.1229 18.1709 20.1586 19.3995C19.9065 19.7208 19.4455 19.7863 19.1104 19.5528L18.263 18.9625C17.9001 18.7096 17.8205 18.203 18.0924 17.8543C19.4618 16.0977 21.0866 14.9677 23.9787 14.9677C27.3542 14.9677 30.2652 16.8872 30.2652 20.1426C30.2652 24.4919 25.269 24.2619 25.269 27.0722V27.0968C25.269 27.5243 24.9224 27.871 24.4948 27.871ZM24 10.0645C31.653 10.0645 37.9355 16.2629 37.9355 24C37.9355 31.6962 31.7026 37.9355 24 37.9355C16.3068 37.9355 10.0645 31.7057 10.0645 24C10.0645 16.3095 16.2969 10.0645 24 10.0645ZM24 8C15.1641 8 8 15.1666 8 24C8 32.8385 15.1641 40 24 40C32.8359 40 40 32.8385 40 24C40 15.1666 32.8359 8 24 8Z"
        fill="currentColor"
      />
    </Icon>
  )
}

import { ReactNode } from 'react'
import { Box, useStyleConfig, useBreakpointValue } from '@chakra-ui/react'
import Script from 'next/script'

import { Desktop } from './Desktop'
import { Mobile } from './Mobile'
import { ALIANT_TOKENIZATION_KEY } from '@/constants'

import { useAgreemetsLockout } from '@/hooks/useAgreementsLockout'
import { AgreementLockout } from '@/features/dashboard/AgreementLockout'

type Props = {
  children: ReactNode
}
export function DashboardLayout({ children }: Props) {
  const styles = useStyleConfig('Box', { variant: 'ghost-white' })

  const layout = useBreakpointValue(
    { base: 'mobile', md: 'desktop' },
    { fallback: 'not-rendered' }
  )

  const {
    isLockoutActive,
    agreement,
    agreementsTotal,
    signAgreement,
    signAgreementLoading,
  } = useAgreemetsLockout()

  return (
    <>
      <Script src="https://7k6nyndnmr6d.statuspage.io/embed/script.js" />
      <Script
        async
        src="https://secure.nmi.com/token/Collect.js"
        data-tokenization-key={ALIANT_TOKENIZATION_KEY}
        data-variant="inline"
        data-custom-css='{
          "padding" : "0 16px",
          "border": "1px solid #E2E8F0",
          "height": "40px",
          "border-radius": "0.375rem"
        }'
        data-focus-css='{
          "z-index": "1",
          "border": "2px solid",
          "border-color": "#f63"
        }'
        data-invalid-css='{
          "z-index": "1",
          "border": "2px solid",
          "border-color": "#E53E3E"
        }'
      />
      <Box __css={styles} minH={{ base: '100vh', md: 'unset' }}>
        <>
          {layout === 'mobile' && (
            <Mobile isPageBlurred={isLockoutActive}>{children}</Mobile>
          )}
          {layout === 'desktop' && (
            <Desktop isPageBlurred={isLockoutActive}>{children}</Desktop>
          )}
          <AgreementLockout
            isLockoutActive={isLockoutActive}
            agreement={agreement}
            agreementsTotal={agreementsTotal}
            signAgreement={signAgreement}
            signAgreementLoading={signAgreementLoading}
          />
        </>
      </Box>
    </>
  )
}

import { ReactNode } from 'react'
import {
  Button,
  Text,
  Link,
  HStack,
  Menu as ChakraMenu,
  MenuButton,
  MenuList,
  MenuItem,
  SkeletonText,
  useColorModeValue,
  Flex,
  Box,
  MenuDivider,
  VStack,
} from '@chakra-ui/react'
import { getCurrentUser } from '@/api/auth'
import { useQuery } from '@apollo/client'
import { useRouter } from 'next/router'
import { useTranslations } from 'next-intl'
import { useLogout } from '@/hooks/useAuthActions'
import { ArrowDownIcon } from '@/components/icons/ArrowDownIcon'
import { QuestionIcon } from '@/components/icons/QuestionIcon'
import { PersonIcon } from '@/components/icons/PersonIcon'
import { SUPPORT_URL, CONTACT_SUPPORT, VIEW_TICKETS_URL } from '@/constants'
import { ColorModeSwitch } from '@/components/ColorModeSwitch'
import { SupportIcon } from '@/components/icons/SupportIcon'

interface IHeaderItem {
  children: ReactNode
}

const HeaderItem = ({ children }: IHeaderItem) => (
  <Box
    _hover={{
      textDecoration: 'none',
    }}
  >
    <HStack
      ml={{ base: '3', md: '5' }}
      cursor="pointer"
      spacing={{ base: '2', md: '1', xl: '4' }}
      fontSize={{ md: '7px', xl: '16px' }}
      lineHeight={{ md: '8px', xl: '18px' }}
      color={useColorModeValue('black', 'gray.250')}
      css={{
        '& .icon': {
          color: 'black',
        },
        '& .nobg': {
          color: 'inherit',
        },
      }}
      _hover={{
        textDecoration: 'none',
        '.chakra-text': {
          color: 'brand.600',
        },
        '& .icon': {
          color: 'brand.600',
        },
      }}
    >
      {children}
    </HStack>
  </Box>
)
export const Header = () => {
  const t = useTranslations()
  const router = useRouter()
  const logout = useLogout(router)
  const { data, loading } = useQuery(getCurrentUser)
  const textColor = useColorModeValue('black', 'gray.250')
  const supportIconColor = useColorModeValue('black', 'white')

  const getFullName = (fullName: string): string => {
    if (!fullName) return ''

    fullName = fullName.replace(/\s{2,}/g, ' ').trim()
    const firstName = fullName.split(' ').at(0) || ''
    const lastName = fullName.split(' ').at(1) || ''

    return `${firstName} ${lastName.length ? `${lastName?.charAt(0)}.` : ''}`
  }

  return (
    <HStack
      justifyContent={'space-between'}
      spacing={{ base: '4', md: '8', xl: '8' }}
      position="sticky"
      top="0"
      bg={useColorModeValue('gray.180', 'black.200')}
      zIndex="10"
      pr={{ base: '0px', md: '20px', xl: '57px' }}
      pl={{ base: '0px', md: '20px', xl: '48px' }}
      py={{ md: '7px', xl: '16px' }}
    >
      <Box>
        <ColorModeSwitch />
      </Box>
      <Flex alignItems={'center'}>
        <HeaderItem>
          <ChakraMenu closeOnSelect={true}>
            <MenuButton
              as={Button}
              variant="transparent"
              pl={{ base: '0', md: '4' }}
              pr={'0'}
            >
              <HStack spacing={{ base: '2', md: '1', xl: '4' }}>
                <SupportIcon
                  _dark={{ fill: supportIconColor }}
                  className="icon"
                  width={{ base: '30px', md: '20.5px', xl: '20px' }}
                  height={{ base: '30px', md: '20.5px', xl: '20px' }}
                />
                <Text
                  display={{ base: 'none', md: 'block' }}
                  fontSize={'lg'}
                  lineHeight={'20px'}
                >
                  {t('dashboard.header-links.support')}
                </Text>
                <ArrowDownIcon
                  className="icon nobg"
                  color={useColorModeValue('gray.650', 'gray.250')}
                  width={{ base: '11px', md: '7px', xl: '16px' }}
                  height={{ base: '11px', md: '7px', xl: '16px' }}
                />
              </HStack>
            </MenuButton>
            <MenuList
              fontFamily={'Inter'}
              wordBreak={'break-all'}
              maxW={'211px'}
              _hover={{
                '.chakra-text': {
                  color: 'initial',
                },
              }}
              _focus={{
                '.chakra-text': {
                  color: 'black',
                },
              }}
              _active={{
                '.chakra-text': {
                  color: 'initial',
                },
              }}
            >
              <Link href={CONTACT_SUPPORT} target="_blank" color={textColor}>
                <MenuItem as={Button} variant="transparent">
                  {t('buttons.contact-support')}
                </MenuItem>
              </Link>
              <MenuDivider mx={4} />
              <Link href={VIEW_TICKETS_URL} target="_blank" color={textColor}>
                <MenuItem as={Button} variant="transparent">
                  {t('buttons.view-tickets')}
                </MenuItem>
              </Link>
            </MenuList>
          </ChakraMenu>
        </HeaderItem>
        <HeaderItem>
          <Link href={SUPPORT_URL} isExternal variant="black" _hover={{
            textDecoration: 'none'
          }}>
            <HStack>
              <QuestionIcon
                className="icon"
                width={{ base: '30px', md: '20.5px', xl: '48px' }}
                height={{ base: '30px', md: '20.5px', xl: '48px' }}
              />
              <Text display={{ base: 'none', md: 'block' }} fontSize={'lg'}>
                {t('dashboard.header-links.faq')}
              </Text>
            </HStack>
          </Link>
        </HeaderItem>
        <HeaderItem>
          <ChakraMenu closeOnSelect={true}>
            <MenuButton
              as={Button}
              variant="transparent"
              pl={{ base: '0', md: '4' }}
              pr={'0'}
            >
              <HStack spacing={{ base: '2', md: '1', xl: '4' }}>
                <PersonIcon
                  className="icon"
                  width={{ base: '30px', md: '20.5px', xl: '48px' }}
                  height={{ base: '30px', md: '20.5px', xl: '48px' }}
                />
                {loading ? (
                  <SkeletonText
                    width="100px"
                    noOfLines={1}
                    skeletonHeight="3"
                  />
                ) : (
                  <Text
                    maxW={['15vw', '100px', '160px', 'auto']}
                    whiteSpace={'nowrap'}
                    textOverflow={'ellipsis'}
                    overflow={'hidden'}
                    fontSize={'lg'}
                  >
                    {data?.currentUser?.nodes?.length > 0 &&
                      getFullName(data?.currentUser?.nodes[0].userFullName)}
                  </Text>
                )}
                <ArrowDownIcon
                  className="icon nobg"
                  color={useColorModeValue('gray.650', 'gray.250')}
                  width={{ base: '11px', md: '7px', xl: '16px' }}
                  height={{ base: '11px', md: '7px', xl: '16px' }}
                />
              </HStack>
            </MenuButton>
            <MenuList
              fontFamily={'Inter'}
              wordBreak={'break-all'}
              maxW={'211px'}
              _hover={{
                '.chakra-text': {
                  color: 'initial',
                },
              }}
              _focus={{
                '.chakra-text': {
                  color: 'black',
                },
              }}
              _active={{
                '.chakra-text': {
                  color: 'initial',
                },
              }}
            >
              <MenuItem
                p={4}
                background={'transparent'}
                _focus={{ background: 'transparent' }}
              >
                <VStack alignItems={'flex-start'}>
                  {data?.currentUser?.nodes[0]?.userFullName && (
                    <Text fontSize={'md'} color={`${textColor} !important`}>
                      {data?.currentUser?.nodes[0].userFullName}
                    </Text>
                  )}
                  {data?.currentUser?.nodes[0]?.userBusinessName && (
                    <Text
                      py={{ base: 2, md: 5, xl: 3 }}
                      fontSize={'md'}
                      color={`${textColor} !important`}
                    >
                      {data?.currentUser?.nodes[0].userBusinessName}
                    </Text>
                  )}
                  {data?.currentUser?.nodes[0]?.userTaxId && (
                    <Text fontSize={'md'} color={`${textColor} !important`}>
                      {data?.currentUser?.nodes[0].userTaxId}
                    </Text>
                  )}
                  {data?.currentUser?.nodes[0]?.userEmail && (
                    <Text
                      as="small"
                      fontSize={'xs'}
                      lineHeight={'18px'}
                      color={`${textColor} !important`}
                    >
                      {data?.currentUser?.nodes[0].userEmail}
                    </Text>
                  )}
                </VStack>
              </MenuItem>
              <MenuDivider mx={4} />
              <MenuItem
                as={Button}
                variant="transparent"
                onClick={logout}
                fontWeight={'bold'}
              >
                {t('buttons.sign-out')}
              </MenuItem>
            </MenuList>
          </ChakraMenu>
        </HeaderItem>
      </Flex>
    </HStack>
  )
}

import { NextRouter } from 'next/router'
import { useMutation } from '@apollo/client'
import { initializeApollo } from '@/utils/apolloClient'

import { loginMutation } from '@/api/auth'
import { useAuthContext } from './useAuthContext'
import { setAuthToken, removeAuthToken } from '@/utils/authToken'
import { showAlertNext } from '@/utils/handleAlert'
import {
  AUTH0_ENABLED,
  LOGIN_DEFAULT_REDIRECT,
  SHOW_AUTOPAY_ALERT,
} from '@/constants'

/**
 * Custom hook to handle authToken - we use compositon to decouple the auth system and it's storage
 * It's used ONLY FOR DEVELOPMENT enviromnent
 */

/* eslint-disable  @typescript-eslint/no-explicit-any */
export function useLogin(router: NextRouter): any {
  const { push, query } = router
  const { refetchAuthStatus } = useAuthContext()
  const [loginFunctionMutation, loginMutationResult] = useMutation(
    loginMutation,
    {
      onCompleted: (data) => {
        if (!data.authenticate) {
          return
        }
        // set token so next request will be sent with Bearer authorizaion header
        setAuthToken(data.authenticate.jwt)

        // set user authenticated
        if (typeof refetchAuthStatus === 'function') {
          refetchAuthStatus()
        }
        const routeToRedirect = String(
          query?.return_to || LOGIN_DEFAULT_REDIRECT
        )
        push(routeToRedirect)
      },
      onError: (error) => {
        return error
      },
    }
  )

  const login = (email: string, password: string) => {
    return loginFunctionMutation({
      variables: {
        email,
        password,
      },
    })
  }

  return [login, loginMutationResult]
}

export function useLogout(router: NextRouter) {
  const { refetchAuthStatus } = useAuthContext()

  return async () => {
    const apolloClient = initializeApollo()
    await apolloClient.clearStore() // we remove all information in the store

    showAlertNext(SHOW_AUTOPAY_ALERT)
    if (AUTH0_ENABLED) {
      router.push('/api/auth/logout')
    } else {
      removeAuthToken()
      window.location.href = '/'
    }

    // set user unauthenticated
    if (typeof refetchAuthStatus === 'function') {
      refetchAuthStatus()
    }
  }
}

const Button = {
  variants: {
    brand: () => ({
      bg: 'brand.600',
      color: 'white',
      _hover: {
        bg: 'brand.50',
        color: 'brand.600',
        _disabled: {
          bg: 'orange.100',
        },
      },
      _active: {
        bg: 'brand.500',
        color: 'white',
      },
    }),
    alwaysWhite: () => ({
      bg: 'white',
      color: 'black',
      _hover: {
        bg: 'brand.50',
        color: 'brand.600',
        _disabled: {
          bg: 'orange.100',
        },
      },
      _active: {
        bg: 'brand.500',
        color: 'white',
      },
    }),
    transparent: () => ({
      bg: 'transparent',
      fontWeight: 'normal',
      _hover: {
        bg: 'transparent',
      },
    }),
    black: () => ({
      bg: 'black',
      color: 'white',
      _hover: {
        bg: 'gray',
        color: 'white',
      },
      _active: {
        bg: 'gray',
        color: 'white',
      },
    }),
    white: () => ({
      bg: 'white',
      color: 'black',
      _hover: {
        opacity: 0.8,
      },
    }),
    brandBlue: () => ({
      bg: 'transparent',
      color: 'brandBlue.50',
      border: '1px',
      borderColor: 'brandBlue.50',
      _hover: {
        bg: 'transparent',
      },
      _dark: {
        color: 'initial',
        borderColor: 'initial',
      },
    }),
    whiteOrange: () => ({
      color: 'orange.550',
      bg: 'white',
      boxShadow: '0px 2px 8px 0px rgba(255, 102, 51, 0.25)',
      _hover: {
        opacity: 0.8,
      },
    }),
  },
}

export default Button

import {
  AUTH_TOKEN,
  AUTH_REFRESH_TOKEN,
  AUTH_REFRESH_TOKEN_PERIOD,
} from '@/constants'

function setLocalStorageItem(key: string, value: string) {
  if (typeof window !== 'undefined') {
    localStorage.setItem(key, value)
  }
}

function getLocalStorageItem(key: string): string | null {
  if (typeof window !== 'undefined') {
    return localStorage.getItem(key)
  }
  return null
}

function removeLocalStorageItem(key: string) {
  if (typeof window !== 'undefined') {
    localStorage.removeItem(key)
  }
}

export const getAuthToken = (): string | null => {
  return getLocalStorageItem(AUTH_TOKEN)
}

export const setAuthToken = (authToken: string) => {
  setRefreshToken()
  setLocalStorageItem(AUTH_TOKEN, authToken)
}
export const removeAuthToken = () => {
  removeLocalStorageItem(AUTH_REFRESH_TOKEN)
  removeLocalStorageItem(AUTH_TOKEN)
}

export const getRefreshToken = (): number | null => {
  const timeString = getLocalStorageItem(AUTH_REFRESH_TOKEN)
  if (timeString) {
    return Number(timeString)
  }
  return null
}

const setRefreshToken = () => {
  const currentTime = new Date().getTime()
  const renewalTime = currentTime + AUTH_REFRESH_TOKEN_PERIOD
  return setLocalStorageItem(AUTH_REFRESH_TOKEN, String(renewalTime))
}

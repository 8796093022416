import { Icon, IconProps } from '@chakra-ui/react'

export function CompassMiningIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 915.18665 976.21332" {...props}>
      <g transform="matrix(1.3333333,0,0,-1.3333333,0,976.21333)" id="g10">
        <g transform="scale(0.1)" id="g12">
          <path
            id="path14"
            fill="currentColor"
            d="m 5089.82,5284.81 c -369.22,279.33 -830.79,441.2 -1337.89,441.2 -1197.11,0 -2167.55,-929.16 -2167.55,-2075.33 0,-1146.17 970.44,-2075.33 2167.55,-2075.33 491.45,0 991.58,192.38 1355.17,456.3 L 5430.32,1567 c -466.63,-347.03 -1041.78,-545.4 -1678.39,-545.4 -1522.36,0 -2756.481,1181.61 -2756.481,2639.21 0,1457.59 1234.121,2639.21 2756.481,2639.21 622.73,0 1207.05,-203.46 1668.58,-536.9 l -330.69,-478.31"
          />
          <path
            id="path16"
            fill="#FF6633"
            d="m 4067.23,3813.08 -782.41,162.72 c 0,0 1606.52,1019.56 1604.79,1027.74 l 0.06,-0.04 c -8.56,-1.67 -359.37,-1815.54 -359.37,-1815.54 -2.58,-1.74 -465.58,623.35 -463.01,625.09 l -0.06,0.03"
          />
        </g>
      </g>
    </Icon>
  )
}

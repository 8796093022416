import { gql } from '@apollo/client'
import { filePartsFragment } from '@/api/strapi/filePartsFragment'

export const getGlobalData = gql`
  ${filePartsFragment}
  query GetGlobal($locale: I18NLocaleCode!) {
    global(locale: $locale) {
      data {
        id
        attributes {
          favicon {
            ...FileParts
          }
          metadata {
            metaTitle
            metaDescription
            shareImage {
              ...FileParts
            }
            twitterCardType
            twitterUsername
          }
          metaTitleSuffix
          notificationBanner {
            type
            text
          }
          companyContacts {
            title
            address
            phone
            contact
          }
          subscribeForm {
            title
            subTitle
            description
          }
          navbar {
            navigationLinks {
              id
              url
              text
              newTab
              submenu {
                id
                url
                text
                newTab
              }
            }
          }
          footer {
            descriptionText
            copyRight
            columns {
              id
              title
              links {
                id
                url
                text
                newTab
                iconName
              }
            }
            smallLinks {
              id
              url
              text
              newTab
            }
          }
        }
      }
    }
  }
`

import { ReactNode } from 'react'
import NextLink from 'next/link'
import {
  Flex,
  Box,
  Stack,
  Collapse,
  Link,
  Text,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react'

import { ContainerBase } from '../containers/ContainerBase'
import { ExpandMoreIcon } from '@/components/icons/ExpandMoreIcon'
import { INavItem } from '@/types/navItem.interface'

type NavbarProps = {
  menuCloseFn: () => void
  navigationLinks: Array<INavItem>
  children?: ReactNode
}

export function NavbarMobile({
  navigationLinks,
  menuCloseFn,
  children,
}: NavbarProps) {
  return (
    <Box
      bg={useColorModeValue('white', 'gray.800')}
      py={4}
      display={{ lg: 'none' }}
      pos={'fixed'}
      zIndex={1000}
      shadow={'md'}
      w={'full'}
      left={0}
    >
      <ContainerBase>
        <Stack>
          {navigationLinks.map((navItem: INavItem) => (
            <MobileNavItem
              key={`nav_mobile_${navItem.id}`}
              menuCloseFn={menuCloseFn}
              {...navItem}
            />
          ))}
          {children}
        </Stack>
      </ContainerBase>
    </Box>
  )
}

interface IMobileNavItem extends INavItem {
  menuCloseFn: () => void
}

function MobileNavItem({
  text,
  submenu,
  newTab,
  url,
  menuCloseFn,
}: IMobileNavItem) {
  const { isOpen, onToggle } = useDisclosure()
  const linkColor = useColorModeValue('gray.600', 'gray.200')
  const borderColor = useColorModeValue('gray.200', 'gray.700')

  if (submenu.length) {
    return (
      <Stack onClick={onToggle}>
        <Flex
          py={2}
          as={Link}
          justify={'space-between'}
          align={'center'}
          _hover={{
            textDecoration: 'none',
          }}
          onClick={() => {
            if (!submenu.length) {
              menuCloseFn()
            }
          }}
        >
          <Text fontWeight={600} color={linkColor}>
            {text}
          </Text>

          <ExpandMoreIcon
            transition={'all .25s ease-in-out'}
            transform={isOpen ? 'rotate(180deg)' : ''}
            w={6}
            h={6}
          />
        </Flex>

        <Collapse
          in={isOpen}
          animateOpacity
          style={{ marginTop: '0!important' }}
        >
          <Stack
            pl={4}
            borderLeft={1}
            borderStyle={'solid'}
            borderColor={borderColor}
            align={'start'}
          >
            {submenu.map((child: INavItem) => (
              <NextLink
                key={`nav_mobile_submenu_${child.id}`}
                href={child.url}
                passHref
              >
                <Link
                  isExternal={child.newTab}
                  py={2}
                  color={linkColor}
                  onClick={() => {
                    menuCloseFn()
                  }}
                >
                  {child.text}
                </Link>
              </NextLink>
            ))}
          </Stack>
        </Collapse>
      </Stack>
    )
  }

  return (
    <Box py={2}>
      <NextLink href={url} passHref>
        <Link
          isExternal={newTab}
          fontWeight={600}
          color={linkColor}
          onClick={menuCloseFn}
        >
          {text}
        </Link>
      </NextLink>
    </Box>
  )
}

import { Icon, IconProps } from '@chakra-ui/react'

export function SunIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 21 22" fill="none" {...props}>
      <g clipPath="url(#clip0_1_4601)">
        <path
          d="M17.25 8.18647V4.19997H13.2635L10.45 1.38647L7.63649 4.19997H3.64999V8.18647L0.836487 11L3.64999 13.8135V17.8H7.63649L10.45 20.6135L13.2635 17.8H17.25V13.8135L20.0635 11L17.25 8.18647ZM10.45 16.1C7.63649 16.1 5.34999 13.8135 5.34999 11C5.34999 8.18648 7.63649 5.89997 10.45 5.89997C13.2635 5.89997 15.55 8.18648 15.55 11C15.55 13.8135 13.2635 16.1 10.45 16.1ZM10.45 7.59997C8.57149 7.59997 7.04999 9.12148 7.04999 11C7.04999 12.8785 8.57149 14.4 10.45 14.4C12.3285 14.4 13.85 12.8785 13.85 11C13.85 9.12148 12.3285 7.59997 10.45 7.59997Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_4601">
          <rect
            width="20.4"
            height="20.4"
            fill="white"
            transform="translate(0.25 0.799988)"
          />
        </clipPath>
      </defs>
    </Icon>
  )
}

import { useTranslations } from 'next-intl'
import { Spinner, Center, CenterProps, VStack, Text } from '@chakra-ui/react'

interface ILoading extends CenterProps {
  text?: string
  hideText?: boolean
  size?: string
}

export function Loading({
  text = '',
  hideText = false,
  size = 'xl',
}: ILoading) {
  const t = useTranslations('common')
  let computedText = text

  // set default text for component if it's not set
  if (!hideText && !text) {
    computedText = t('loading')
  }

  return (
    <Center p={4} minH={'400px'}>
      <VStack>
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="brand.50"
          color="brand.600"
          size={size}
        />
        {computedText && (
          <Text p={4} fontSize={'xl'}>
            {computedText}
          </Text>
        )}
      </VStack>
    </Center>
  )
}

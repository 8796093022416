import { Icon, IconProps } from '@chakra-ui/react'

export function CartsIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 20 20" fill="none" {...props}>
      <path
        d="M6.875 16.875C7.22018 16.875 7.5 16.5952 7.5 16.25C7.5 15.9048 7.22018 15.625 6.875 15.625C6.52982 15.625 6.25 15.9048 6.25 16.25C6.25 16.5952 6.52982 16.875 6.875 16.875Z"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.625 16.875C15.9702 16.875 16.25 16.5952 16.25 16.25C16.25 15.9048 15.9702 15.625 15.625 15.625C15.2798 15.625 15 15.9048 15 16.25C15 16.5952 15.2798 16.875 15.625 16.875Z"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.875 3.125H4.375L6.25 13.75H16.25"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.25 11.25H15.9938C16.066 11.2501 16.1361 11.225 16.192 11.1793C16.2479 11.1335 16.2862 11.0697 16.3004 10.9988L17.4254 5.37383C17.4345 5.32847 17.4334 5.28166 17.4222 5.23678C17.4109 5.19191 17.3899 5.15008 17.3606 5.11431C17.3312 5.07855 17.2943 5.04974 17.2525 5.02997C17.2107 5.0102 17.165 4.99997 17.1187 5H5"
        stroke="currentColor"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  )
}

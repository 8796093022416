import { Icon, IconProps } from '@chakra-ui/react'

export function MinersIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 20 20" fill="none" {...props}>
      <rect
        x="2.6"
        y="2.6"
        width="14.8"
        height="4.18462"
        rx="2.09231"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
      <rect
        x="2.6"
        y="7.6"
        width="14.8"
        height="4.18462"
        rx="2.09231"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
      <rect
        x="2.6"
        y="12.2924"
        width="14.8"
        height="4.18462"
        rx="2.09231"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
      <circle cx="5.5" cy="4.5" r="0.5" fill="currentColor" />
      <circle cx="5.5" cy="9.5" r="0.5" fill="currentColor" />
      <circle cx="5.5" cy="14.5" r="0.5" fill="currentColor" />
    </Icon>
  )
}

import { Icon, IconProps } from '@chakra-ui/react'

export function CircleRightArrowIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 21 21" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5 21C16.299 21 21 16.299 21 10.5C21 4.70101 16.299 0 10.5 0C4.70101 0 0 4.70101 0 10.5C0 16.299 4.70101 21 10.5 21Z"
        fill="currentColor"
      />
      <path
        d="M5 10.5018C5 10.1744 5.28064 9.90907 5.62682 9.90907H13.6158L10.5601 7.0196C10.3104 6.78344 10.3139 6.39951 10.5681 6.16755C10.8159 5.94131 11.2098 5.94459 11.4534 6.17494L15.9794 10.4548C16.0069 10.4808 16.0069 10.5228 15.9794 10.5487L11.4543 14.8278C11.2114 15.0574 10.8177 15.0574 10.5748 14.8277C10.3327 14.5988 10.3319 14.228 10.5728 13.998L13.6158 11.0945H5.62682C5.28064 11.0945 5 10.8291 5 10.5018Z"
        fill="white"
      />
    </Icon>
  )
}

import type {
  GlobalStyleProps,
  StyleFunctionProps,
} from '@chakra-ui/theme-tools'
import { mode } from '@chakra-ui/theme-tools'
import defaultTheme from '@chakra-ui/theme'

const Table = {
  baseStyle: (props: GlobalStyleProps) => ({
    table: {
      color: mode('black.100', 'gray.250')(props),
      bg: mode('white', 'black.200')(props),
    },
    th: {
      height: '35px',
      position: 'sticky',
      top: '0',
      zIndex: '10',
      paddingInlineStart: '0',
      paddingInlineEnd: '0',
      pt: '0',
      fontWeight: '600',
      fontSize: '12px',
      lineHeight: '12px',
    },
    tr: {
      borderBottomWidth: '1px',
      borderBottomStyle: 'solid',
      borderBottomColor: mode('gray.50', 'gray.850')(props),
    },
    td: {
      height: '50px',
      fontWeight: 'normal',
      fontSize: '12px',
      lineHeight: '12px',
    },
  }),
  variants: {
    striped: (props: StyleFunctionProps) => ({
      ...defaultTheme.components.Table.variants.striped(props),
      tr: {
        '&:nth-of-type(odd) td': {
          borderBottomWidth: 0,
          background: mode('gray.50 !important', 'gray.220 !important')(props),
          color: mode('inherit', 'black.100 !important')(props),
          '& p': {
            color: mode('', 'black.100 !important')(props),
          },
        },
      },
      td: {
        paddingTop: '10px',
        paddingBottom: '10px',
        borderBottomWidth: 0,
        borderColor: 'transparent',
      },
    }),
  },
}

export default Table

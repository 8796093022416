import { Icon, IconProps } from '@chakra-ui/react'

export function SunMoonIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M20 8.69V4H15.31L12 0.690002L8.69 4H4V8.69L0.690002 12L4 15.31V20H8.69L12 23.31L15.31 20H20V15.31L23.31 12L20 8.69ZM12 18C11.11 18 10.26 17.8 9.5 17.45C11.56 16.5 13 14.42 13 12C13 9.58 11.56 7.5 9.5 6.55C10.26 6.2 11.11 6 12 6C15.31 6 18 8.69 18 12C18 15.31 15.31 18 12 18Z"
        fill="currentColor"
      />
    </Icon>
  )
}

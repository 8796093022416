import {
  Box,
  Center,
  Checkbox,
  Flex,
  FormLabel,
  Icon,
  useColorMode,
} from '@chakra-ui/react'
import { MoonIcon } from '@/components/icons/MoonIcon'
import { SunIcon } from '@/components/icons/SunIcon'

export const ColorModeSwitch = () => {
  const { colorMode, toggleColorMode } = useColorMode()

  return (
    <FormLabel
      htmlFor="color-switch"
      position="relative"
      width={{ base: '60px', md: '85px' }}
      height={{ base: '30px', md: '35px' }}
      display="block"
      bg="gray.220"
      borderRadius="full"
      color="white"
      mb={0}
    >
      <Checkbox
        id="color-switch"
        onChange={toggleColorMode}
        isChecked={colorMode === 'light'}
        position="absolute"
        visibility="hidden"
        _checked={{
          '+ .active-indicator:before': {
            transform: 'translateX(100%)',
          },
        }}
      />
      <Box
        className={`active-indicator`}
        position="absolute"
        cursor="pointer"
        top="0"
        left="0"
        transition="0.4s"
        _before={{
          position: 'absolute',
          left: 0,
          zIndex: 1,
          content: "''",
          height: { base: '30px', md: '35px' },
          width: { base: '30px', md: '42.5px' },
          borderRadius: 'full',
          bg: 'black',
          transition: '0.4s',
        }}
      />
      <Flex
        width="100%"
        justifyContent="space-between"
        alignItems="center"
        height="100%"
        zIndex="2"
        position="relative"
      >
        <Center
          width={{ base: '30px', md: '42.5px' }}
          height="auto"
          padding={{ base: 1.5, md: 2.5 }}
        >
          <Icon as={MoonIcon} height="100%" width="100%" />
        </Center>
        <Center
          width={{ base: '30px', md: '42.5px' }}
          height="auto"
          padding={{ base: 1.5, md: 2.5 }}
        >
          <Icon as={SunIcon} height="100%" width="100%" />
        </Center>
      </Flex>
    </FormLabel>
  )
}

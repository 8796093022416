import { Icon, IconProps } from '@chakra-ui/react'

export function PersonIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 48 48" fill="none" {...props}>
      <circle cx={24} cy={24} r={24} fill="#EDEEF0" />
      <circle
        cx="24.5"
        cy="16.5"
        r="5.5"
        stroke="currentColor"
        strokeWidth={2}
      />
      <path
        d="M13 35C13 29.4772 17.4772 25 23 25H26C31.5228 25 36 29.4772 36 35C36 36.1046 35.1046 37 34 37H15C13.8954 37 13 36.1046 13 35Z"
        stroke="currentColor"
        strokeWidth={2}
      />
    </Icon>
  )
}

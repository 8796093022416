import { gql } from '@apollo/client'

export const alterCart = gql`
  mutation AlterCart($productId: UUID!, $quantity: Int!) {
    alterCart(input: { productId: $productId, quantity: $quantity }) {
      boolean
    }
  }
`

export const getAllAvailableCredits = gql`
  query allCredits {
    availableCompassCredits
  }
`
export const getAllCarts = gql`
  query AllCarts {
    allCarts(orderBy: PRODUCT_NAME_ASC) {
      totalCount
      nodes {
        addressId
        cartStartTime
        cartLastTimeActive
        cartItemProductId
        cartItemQuantity
        compassScore
        facilityId
        facilityCountryCode
        facilityCountryName
        facilityDisplayName
        hostedPrepaymentMonths
        hostedDepositMonths
        hostedContractLengthYears
        hostedTargetOnlineDate
        isProductTurnkey
        minerModelId
        minerModelAlgorithm
        minerModelType
        minerModelHashratePerTerahash
        minerModelName
        minerModelPowerConsumptionWatts
        nonhostedEarliestShippingDate
        nonhostedShippingTimeDescription
        marketplacePrepaymentMonths
        marketplaceDepositMonths
        monthlyHostingCharges
        priceKwh
        productCondition
        productDiscountAmount
        productDiscountPercent
        productDiscountReason
        productDiscountNotes
        productPaymentPlanDepositAtCheckout
        productPaymentPlanFirstIncrementPaymentDue
        productPaymentPlanIncrementsInterval
        productPaymentPlanIncrementsTotal
        productListingPrice
        productName
        setupFees
        type
      }
    }
  }
`

export const getCartQuantity = gql`
  query getCartQuantity {
    allCarts {
      totalCount
      nodes {
        cartItemQuantity
      }
    }
  }
`

export const getConditionalPaymentmethods = gql`
  query getPaymentMethods($isCheckout: Boolean!) {
    conditionalPaymentMethods(isCheckout: $isCheckout) {
      nodes {
        methodFeePercent
        methodFeeAmount
        methodFeeText
        paymentMethod
        paymentProcessor
      }
    }
  }
`

export const getHardwareCosts = gql`
  query hardwareCosts($postalCode: String) {
    hardwareCosts(postalCode: $postalCode) {
      nodes {
        productId
        totalCost
        productCost
        taxAmount
        powerCost
        hostingPrepayment
        securityDeposit
        hostingPrepaymentSalesTax
        depositCost
        singleHostingPrepayment
        singleSecurityDeposit
      }
    }
  }
`

export const getCheckoutTotal = gql`
  query getCheckoutTotal(
    $postalCode: String
    $compassCreditsAmountUsed: Float!
    $compassWalletAmountUsed: Float!
    $paymentMethod: String
    $offerId: String
  ) {
    getCheckoutTotal(
      postalCode: $postalCode
      compassCreditsAmountUsed: $compassCreditsAmountUsed
      compassWalletAmountUsed: $compassWalletAmountUsed
      paymentMethod: $paymentMethod
      offerId: $offerId
    ) {
      nodes {
        orderTotal
        totalHardwareCosts
        totalHostingPrepayment
        totalSecurityDeposit
        totalTaxAmount
        totalFees
        totalFacilitySetupFee
        totalOfferAmount
      }
    }
  }
`

export const getOrderTotalByOrderId = gql`
  query getOrderTotal(
    $currentOrderId: UUID!
    $paymentMethod: String
    $binNumber: String
  ) {
    getOrderTotal(
      currentOrderId: $currentOrderId
      paymentMethod: $paymentMethod
      binNumber: $binNumber
    ) {
      edges {
        node {
          orderTotal
          totalHostingPrepayment
          totalSecurityDeposit
          totalHardwareCosts
          totalTaxAmount
          totalFees
          totalFacilitySetupFees
          totalCreditsAmount
          totalOfferAmount
        }
      }
    }
  }
`

export const getOrderStatus = gql`
  query getOrderStatus($orderId: UUID!) {
    getOrderStatus(orderId: $orderId) {
      nodes {
        changeEvent
        orderStatus
      }
    }
  }
`

export const placeOrder = gql`
  mutation PlaceOrder(
    $address: String!
    $businessName: String
    $city: String!
    $compassCreditsAmountUsed: Float!
    $compassWalletAmountUsed: Float!
    $compassPaymentPlanSelected: Boolean!
    $country: String!
    $firstName: String!
    $lastName: String!
    $paymentMethod: String!
    $postalCode: String!
    $phoneNumber: String!
    $region: String!
    $offerId: String
  ) {
    placeOrder(
      input: {
        address: $address
        businessName: $businessName
        city: $city
        compassCreditsAmountUsed: $compassCreditsAmountUsed
        compassPaymentPlanSelected: $compassPaymentPlanSelected
        compassWalletAmountUsed: $compassWalletAmountUsed
        country: $country
        firstName: $firstName
        lastName: $lastName
        paymentMethod: $paymentMethod
        postalCode: $postalCode
        phone: $phoneNumber
        region: $region
        offerId: $offerId
      }
    ) {
      clientMutationId
      uuid
    }
  }
`

export const getNonHostedTaxRate = gql`
  query nonHostedTaxRate($address: String!, $postalCode: String!) {
    nonhostedTaxRate(address: $address, postalCode: $postalCode)
  }
`

export const validateDeliveryAddress = gql`
  query validateDeliveryAddress(
    $city: String!
    $country: String!
    $state: String!
    $street: String!
    $zipcode: String!
  ) {
    validateDeliveryAddress(
      city: $city
      country: $country
      state: $state
      street: $street
      zipcode: $zipcode
    )
  }
`

export const createAchOrCardPayment = gql`
  mutation createAchOrCardPayment(
    $accountNumberHash: String!
    $addressOne: String!
    $addressTwo: String!
    $binNumber: String
    $city: String!
    $country: String!
    $firstName: String!
    $fullNameHash: String!
    $lastName: String!
    $invoiceOrOrderIds: [UUID]!
    $paymentToken: String!
    $paymentMethodType: PaymentMethodType!
    $phone: String!
    $postalCode: String!
    $state: String!
  ) {
    createAchOrCardPayment(
      input: {
        accountNumberHash: $accountNumberHash
        addressOne: $addressOne
        addressTwo: $addressTwo
        binNumber: $binNumber
        city: $city
        country: $country
        firstName: $firstName
        fullNameHash: $fullNameHash
        lastName: $lastName
        invoiceOrOrderIds: $invoiceOrOrderIds
        paymentMethodType: $paymentMethodType
        paymentToken: $paymentToken
        phone: $phone
        postalCode: $postalCode
        state: $state
      }
    ) {
      clientMutationId
      string
    }
  }
`

export const processLightningOrOnchainPayment = gql`
  mutation processLightningOrOnchainPayment(
    $invoiceOrOrderIds: [UUID]!
    $lightningOrCrypto: LightningOrCrypto!
    $successUrl: String!
  ) {
    createCompassCryptoCharge(
      input: {
        invoiceOrOrderIds: $invoiceOrOrderIds
        lightningOrCrypto: $lightningOrCrypto
        successUrl: $successUrl
      }
    ) {
      results {
        chargeBtcAmount
        chargeCreatedAt
        chargeFiatValue
        chargeId
        chargeSendToAddress
        chargeStatus
        compassQrcode
      }
    }
  }
`

export const getPaymentTransactionIdByOrderId = gql`
  query getPaymentTransactionId($orderId: UUID!) {
    getPaymentTransactionId(orderId: $orderId)
  }
`

export const processCoinbasePayment = gql`
  mutation processCoinbasePayment($orderId: [UUID]!, $redirectUrl: String!) {
    createCryptoCharge(
      input: { invoiceOrOrderIds: $orderId, redirectUrl: $redirectUrl }
    ) {
      results {
        compassRedirectUrl
        chargeHostedUrl
        chargeCode
      }
    }
  }
`

export const getPaymentStatus = gql`
  query getPaymentStatus($chargeId: String, $invoiceOrOrderId: [UUID]) {
    paymentStatus(chargeId: $chargeId, invoiceOrOrderId: $invoiceOrOrderId)
  }
`

export const validatePromoCode = gql`
  query validatePromoCode($promoCode: String) {
    validatePromoCode(promoCode: $promoCode) {
      nodes {
        offerId
        offerDescription
        offerMaxRedeemableUsd
        offerRemainingRedeemableBalance
        offerExpirationDate
      }
    }
  }
`

// Navigation
export const BLOG_URL = 'https://compassmining.io/education'
export const PRIVACY_POLICY = `${BLOG_URL}/privacy-policy`
export const CAREERS_URL = 'https://compass-mining-inc.rippling-ats.com/'
export const SUPPORT_URL = 'https://support.compassmining.io/hc/en-us'
export const CONTACT_SUPPORT = `${SUPPORT_URL}/requests/new`
export const VIEW_TICKETS_URL = `${SUPPORT_URL}/requests`
export const SUPPORT_PAYMENT_PLAN_URL = `${SUPPORT_URL}/requests/new?ticket_form_id=360003754537`
export const SUPPORT_MARKETPLACE_URL = `${SUPPORT_URL}/requests/new?ticket_form_id=4412962503953`
export const SUPPORT_COMPASS_WALLET_URL = `${SUPPORT_URL}/articles/5292424262941`
export const SUPPORT_VIDEO_TUTORIAL_URL = `${SUPPORT_URL}/articles/5046934086045`
export const SUPPORT_FACILITY_APPLICATION_URL = `${SUPPORT_URL}/requests/new?ticket_form_id=360003715397`
export const SUPPORT_PROCUREMENT_SERVICE_URL = `${SUPPORT_URL}/requests/new?ticket_form_id=360003201978`
export const SUPPORT_MINING_POOL_CONFIGURATION_URL = `${SUPPORT_URL}/articles/4446509937821`
export const SUPPORT_MINING_POOL_REQUEST_URL = `${SUPPORT_URL}/requests/new?ticket_form_id=360003449678`
export const SUPPORT_APPLY_ACCELERATED_URL = `${SUPPORT_URL}/requests/new?ticket_form_id=14075236897437`
export const SUPPORT_PREPAID_CREDITS_URL = `${SUPPORT_URL}/articles/20509757000733-Credit-FAQs`
export const ALIANT_TOKENIZATION_KEY =
  process.env.NEXT_PUBLIC_ALIANT_TOKENIZATION_KEY
export const SITE_URL =
  process.env.NEXT_PUBLIC_SITE_URL || 'https://compassmining.io'

// Page to redirect after successful login
export const LOGIN_DEFAULT_REDIRECT = '/dashboard/miners/hosted'
// Page to redirect after successful sign up process
export const REGISTER_DEFAULT_REDIRECT = '/dashboard/settings'
// This URL should have absolute path
export const PAYMENT_SUCCESS_URL = `${SITE_URL}/dashboard/miners`
export const DASHBOARD_BILLING_TABS = [
  {
    text: 'dashboard.billing.outstanding-invoices',
    path: '/dashboard/billing',
  },
  {
    text: 'dashboard.billing.payment-history-and-credits',
    path: '/dashboard/billing/history',
  },
  {
    text: 'dashboard.billing.automatic-payments',
    path: '/dashboard/billing/automatic-payments',
  },
]
export const SETTING_TABS = [
  {
    text: 'dashboard.settings.tabs.general-tab',
    path: '/dashboard/settings',
  },
]

export const MINING_POOL_SETTING_TABS = [
  {
    text: 'dashboard.mining-pool.your-pool-accounts',
    path: '/dashboard/mining-pool',
  },
  // Uncomment when we have the backend ready
  // {
  //   text: 'dashboard.mining-pool.assign-pools',
  //   path: '/dashboard/settings',
  // },
]

export const MINERS_TABS = [
  {
    text: 'dashboard.miners.hosted-miners',
    path: '/dashboard/miners/hosted',
  },
  {
    text: 'dashboard.miners.home-miners',
    path: '/dashboard/miners/home-miners',
  },
]

export const ENABLE_PREPAID_CREDITS_UI =
  process.env.NEXT_PUBLIC_ENABLE_PREPAID_CREDITS_UI

export const DASHBOARD_OFFERS_TABS = (userHasMiners: boolean) => {
  const tabs = [
    {
      text: 'dashboard.menu.offers',
      path: '/dashboard/my-offers',
    },
  ]

  if (ENABLE_PREPAID_CREDITS_UI === 'true' && userHasMiners) {
    tabs.push({
      text: 'dashboard.menu.prepaid-credits',
      path: '/dashboard/prepaid-credits',
    })
  }

  return tabs
}

export const ENABLE_OFFERS_UI = process.env.NEXT_PUBLIC_ENABLE_OFFERS_UI

export const ENABLE_MY_OFFERS_DASHBOARD_UI =
  process.env.NEXT_PUBLIC_ENABLE_MY_OFFERS_DASHBOARD_UI

// API layer
export const API_URL = process.env.NEXT_PUBLIC_API_URL
export const AUTH_TOKEN = '_jwt'
export const AUTH_REFRESH_TOKEN = 'refreshToken'
export const AUTH_REFRESH_TOKEN_PERIOD = 60000 * 5 // 60000 * X = X minutes

// Auth0 values
export const AUTH0_ENABLED =
  process.env.NEXT_PUBLIC_AUTH0_ENABLED === '1' ||
  process.env.NODE_ENV == 'production'

// Strapi CRM URLs
export const STRAPI_URL = process.env.NEXT_PUBLIC_STRAPI_URL
export const STRAPI_API_URL = `${STRAPI_URL}/graphql`

// Messages labels (for localization)
export const DEFAULT_ERROR = 'default-error'

// Default placeholders
export const MINER_PLACEHOLDER = '/img/placeholder-hardware.png'
export const FACILITY_PLACEHOLDER = '/img/placeholder-facility.jpeg'
export const FACILITY_TABLE_PLACEHOLDER = '/img/placeholder-facility.png'

export const ZAPIER_SUBSCRIBE_HOOK =
  'https://hooks.zapier.com/hooks/catch/8515176/ow8qcrb/'

export const COINBASE_COMMERCE_CHARGE_URL =
  'https://commerce.coinbase.com/charges'

// Invoice Status
export const INVOICE_STATUS = {
  PAID: 'PAID',
  DELETED: 'DELETED',
  PARTIALLY_REFUNDED: 'PARTIALLY_REFUNDED',
  FULLY_REFUNDED: 'FULLY_REFUNDED',
  NOT_PAID: 'NOT_PAID',
  LATE: 'LATE',
  EXPIRED: 'EXPIRED',
  PENDING: 'PENDING',
  CANCELLED: 'CANCELLED',
  FAILED: 'FAILED',
}

export const HARDWARE_ITEMS_TYPES = {
  LATE_FEE: 'LATE_FEE',
  BUNDLE: 'BUNDLE',
  FINANCING: 'FINANCING',
  REPAIR: 'REPAIR',
  SALES_TAX: 'SALES_TAX',
  SHIPPING: 'SHIPPING',
  STORAGE: 'STORAGE',
  TERMINATION: 'TERMINATION',
  HARDWARE: 'HARDWARE',
}

export const NEXT_PUBLIC_BLOG_CONTENT_API =
  process.env.NEXT_PUBLIC_BLOG_CONTENT_API
export const NEXT_PUBLIC_BLOG_CONTENT_API_KEY =
  process.env.NEXT_PUBLIC_BLOG_CONTENT_API_KEY
export const BLOG_CONTENT_API = `${NEXT_PUBLIC_BLOG_CONTENT_API}{resource}/?key=${NEXT_PUBLIC_BLOG_CONTENT_API_KEY}`

// product types for URL filtering (it is not represented in Graphl Queries filters )
export const PRODUCT_TYPE_FEATURED = 'FEATURED'
export const PRODUCT_TYPE_TURNKEY = 'TURNKEY'
export const PRODUCT_TYPE_HOSTED = 'HOSTED'
export const PRODUCT_TYPE_AHM = 'AHM'
export const PRODUCT_TYPE_RESELLER = 'RESELLER'
export const PRODUCT_TYPES = [
  PRODUCT_TYPE_TURNKEY,
  PRODUCT_TYPE_HOSTED,
  PRODUCT_TYPE_AHM,
  PRODUCT_TYPE_RESELLER,
]

// Invoice Types
export const INVOICE_TYPES = {
  HOSTING: 'HOSTING',
  HARDWARE: 'HARDWARE',
  PROTECTION: 'PROTECTION',
  CREDITS: 'CREDITS',
}

export const PAYMENT_TYPES = {
  ACH: 'aliant:ach',
  CARD: 'aliant:card',
  WIRE: 'aliant:wire',
  CRYPTO: 'coinbase:crypto',
  LIGHTNING: 'opennode:lightning',
  BITCOIN: 'opennode:crypto',
}

export const PAYMENT_METHODS = {
  ACH: 'ACH',
  CARD: 'CARD',
  WIRE: 'WIRE',
  CRYPTO: 'CRYPTO',
  LIGHTNING: 'LIGHTNING',
}

// Orders
export const ORDER_STATUS = {
  WAITING_FUNDS: 'WAITING_FUNDS',
  PENDING: 'PENDING',
  CANCELLED: 'CANCELLED',
  CANCELLED_BY_USER: 'CANCELLED_BY_USER',
  COMPLETED: 'COMPLETED',
  REFUNDED: 'REFUNDED',
  DELIVERED: 'DELIVERED',
  OVERSOLD: 'OVERSOLD',
  BUYBACK_OR_LIQUIDATION: 'BUYBACK_OR_LIQUIDATION',
  OTHER: 'OTHER',
  FORFEITED: 'FORFEITED',
  FAILED: 'FAILED',
}

// Checkout Values
export const FIXED_ALIANT_FEE_FOR_CARD_TEXT = '3%'
// TODO [AM]: Fees for invoices should be calculated on the backend
// make a request to create an API to get those fees
export const FIXED_ALIANT_FEE = 0.03
export const MAX_CREDIT_ALLOWED_PER_PRODUCT = 500

// Show Setup Autopay Alert localstorage key
export const SHOW_AUTOPAY_ALERT = 'show_autopay_alert'

export const TURNSTILE_KEY = process.env.NEXT_PUBLIC_TURNSTILE_KEY

export const CALENDLY_LINK =
  'https://calendly.com/d/dxp-ckd-zsm/compass-sales-call?month=2023-06&utm_source%5B%5D=Sendgrid%20Emails&utm_source%5B%5D=Sendgrid%20Emails&utm_source%5B%5D=Sendgrid%20Emails&utm_source%5B%5D=Sendgrid%20Emails&utm_medium%5B%5D=Email&utm_medium%5B%5D=Email&utm_medium%5B%5D=Email&utm_medium%5B%5D=Email&utm_campaign%5B%5D=Compass%20Mining%20Emails&utm_campaign%5B%5D=Compass%20Mining%20Emails&utm_campaign%5B%5D=Compass%20Mining%20Emails&utm_campaign%5B%5D=Compass%20Mining%20Emails&utm_term%5B%5D=Compass%20Mining%20Email&utm_term%5B%5D=Compass%20Mining%20Email&utm_term%5B%5D=Compass%20Mining%20Email&utm_term%5B%5D=Compass%20Mining%20Email&utm_content%5B%5D=Sendgrid-emails&utm_content%5B%5D=Sendgrid-emails&utm_content%5B%5D=Sendgrid-emails&utm_content%5B%5D=Sendgrid-emails'

export const PDP_COVERAGE_STATUS = {
  AVAILABLE: 'AVAILABLE',
  NOT_AVAILABLE: 'NOT_AVAILABLE',
  COVERED: 'COVERED',
  OUTSTANDING_COVERAGE_INVOICE: 'OUTSTANDING_COVERAGE_INVOICE',
}

export const MINERS_SORT_OPTIONS = {
  MINER_MODEL_NAME: 'MODEL_NAME',
  MODEL_NAME_ASC: 'MODEL_NAME_ASC',
  MODEL_NAME_DESC: 'MODEL_NAME_DESC',
  HARDWARE_HASHRATE: 'HARDWARE_HASHRATE',
  HARDWARE_HASHRATE_ASC: 'HARDWARE_HASHRATE_ASC',
  HARDWARE_HASHRATE_DESC: 'HARDWARE_HASHRATE_DESC',
  HARDWARE_POWER: 'HARDWARE_POWER',
  HARDWARE_POWER_ASC: 'HARDWARE_POWER_ASC',
  HARDWARE_POWER_DESC: 'HARDWARE_POWER_DESC',
  FACILITY_CODE: 'FACILITY_DISPLAY_NAME',
  FACILITY_CODE_ASC: 'FACILITY_DISPLAY_NAME_ASC',
  FACILITY_CODE_DESC: 'FACILITY_DISPLAY_NAME_DESC',
  MINER_MONTHLY_FEES: 'MONTHLY_FEE',
  MONTHLY_FEE_ASC: 'MONTHLY_FEE_ASC',
  MONTHLY_FEE_DESC: 'MONTHLY_FEE_DESC',
  TERM_LENGTH: 'TERM_END',
  TERM_END_ASC: 'TERM_END_ASC',
  TERM_END_DESC: 'TERM_END_DESC',
}

// User Verification urls
export const START_INDIVIDUAL_KYC =
  'https://qf97.app.link/0gL69PUUZx?clientkey=A84E58E0'

export const REQUIRE_AGREEMENTS_SIGN: string =
  process.env.NEXT_PUBLIC_REQUIRE_AGREEMENTS_SIGN
export const FETCH_PDF_AGREEMENTS: string =
  process.env.NEXT_PUBLIC_FETCH_PDF_AGREEMENTS

export const FACILITIES_WITH_RELOCATION: Array<string> = (
  process.env.NEXT_PUBLIC_FACILITIES_WITH_RELOCATION || ''
).split(',')

import { Icon, IconProps } from '@chakra-ui/react'

export function MoonIcon(props: IconProps) {
  return (
    <Icon viewBox="0 0 22 22" fill="none" {...props}>
      <g clipPath="url(#clip0_1_4598)">
        <path
          d="M10.9585 11C10.9585 7.96545 12.8285 5.37295 15.472 4.31045C16.2285 4.00445 16.1095 2.87395 15.3105 2.69545C14.3755 2.49145 13.381 2.44045 12.3525 2.57645C8.51899 3.08645 5.45049 6.23995 5.05099 10.082C4.52399 15.1905 8.51049 19.5 13.5085 19.5C14.129 19.5 14.724 19.432 15.3105 19.3045C16.118 19.126 16.2455 18.004 15.4805 17.6895C12.7435 16.593 10.95 13.941 10.9585 11Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_4598">
          <rect
            width="20.4"
            height="20.4"
            fill="white"
            transform="translate(0.75 0.799988)"
          />
        </clipPath>
      </defs>
    </Icon>
  )
}
